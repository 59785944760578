import { FC } from 'react'

interface Props {
  amount?: number | null
  currency?: string
}

const CurrencyCell: FC<Props> = ({ amount, currency = 'USD' }) => {
  if (amount == null) {
    return null
  }

  return (
    <span data-test-id="currencyCell">
      {`${currency} ${amount.toFixed(2)}`}
    </span>
  )
}

export default CurrencyCell
