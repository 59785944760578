import { UseQueryResult, useQuery } from '@tanstack/react-query'
import {
  GuaranteePolicy,
  StoreData,
  getStoreById,
  listInvestigations,
  listTeamGuaranteePolicies,
} from '@signifyd/http'
import { EnhancedUserTeam, TeamIds } from 'core/types'
import useGetUserTeams from './useGetUserTeams'

const getTeamPolicyResponse = async (
  team: EnhancedUserTeam
): Promise<GuaranteePolicy | undefined> => {
  try {
    const { data: teamPolicyResponse } = await listTeamGuaranteePolicies(
      team.teamId
    )

    if (teamPolicyResponse.policies.length) {
      return teamPolicyResponse.policies[0]
    }

    return undefined
  } catch {
    return undefined
  }
}

const getTeamInvestigationsResponse = async (
  team: EnhancedUserTeam
): Promise<string | undefined> => {
  try {
    const listInvestigationsQueryParam =
      '&limit=1&orderBy=SORT_NORMALIZED_CREATED_AT&ascending=false'

    const { data: teamInvestigationsResponse } = await listInvestigations(
      `teamIds=${team.teamId}${listInvestigationsQueryParam}`
    )

    if (teamInvestigationsResponse.investigations.length) {
      return teamInvestigationsResponse?.investigations[0]?.createdAt
    }

    return undefined
  } catch {
    return undefined
  }
}

const getTeamOnlineStore = async (
  onlineStoreId: number
): Promise<StoreData | undefined> => {
  try {
    const { data: teamOnlineStore } = await getStoreById(onlineStoreId)

    return teamOnlineStore
  } catch {
    return undefined
  }
}

const useGetEnhancedUserTeams = (
  teamIdsToEnhance: TeamIds
): UseQueryResult<Array<EnhancedUserTeam>> => {
  const { data: userTeams } = useGetUserTeams()

  const userTeamsIds = userTeams?.map(({ teamId }) => teamId)

  return useQuery(
    ['enhancedUserTeams', teamIdsToEnhance, userTeamsIds],
    async () => {
      if (!teamIdsToEnhance?.length || !userTeams?.length) {
        return []
      }

      const filteredTeams = userTeams.filter(({ teamId }) => {
        return teamIdsToEnhance.includes(teamId.toString())
      })

      const getEnhancedUserTeams: Array<EnhancedUserTeam> = await Promise.all(
        filteredTeams.map(async (team: EnhancedUserTeam) => {
          team.policy = await getTeamPolicyResponse(team)

          team.lastCreatedAt = await getTeamInvestigationsResponse(team)

          if (team.onlineStoreId) {
            team.onlineStore = await getTeamOnlineStore(team.onlineStoreId)
          }

          return team
        })
      )

      return getEnhancedUserTeams
    },
    { enabled: !!userTeams?.length }
  )
}

export default useGetEnhancedUserTeams
