import { FC, useMemo } from 'react'
import { ConfigProvider, Tabs } from 'antd'
import cx from 'classnames'
import { Editor } from 'codemirror'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { useTranslation } from 'react-i18next'
import xmlFormat from 'xml-formatter'
import { StretchToPageBottom, Text } from '@signifyd/components'
import LoadingSpinner from 'core/components/LoadingSpinner'
import { isApiEvent, SelectedEvent } from 'core/utils/integrationEvents/utils'
import { useStoreState } from 'stores'
import CopyButton from '../CopyButton'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import { TabKeys } from '../../JSONViewerPage'
import styles from './JSONViewer.less'

interface JSONViewerProps {
  event?: SelectedEvent
  isLoading?: boolean
  onSelectedTabChange: (key: TabKeys) => void
  selectedTab: TabKeys
  text?: string
}

interface PanelTabsProps {
  event?: SelectedEvent
  isLoading?: boolean
  onSelectedTabChange: (key: TabKeys) => void
  selectedTab: TabKeys
  showFirstTab: boolean
  type: 'api' | 'webhook'
  viewerData: ViewerData
}

interface JSONPanelProps {
  event?: SelectedEvent
  isLoading?: boolean
  selectedTab: TabKeys
  viewerData: ViewerData
}

type ViewerData = {
  format: 'json' | 'xml' | 'string'
  value: string
}

const getJsonString = (str: string): string | undefined => {
  try {
    return JSON.stringify(JSON.parse(str), null, 2)
  } catch (e) {
    return undefined
  }
}

const getXmlString = (str: string): string | undefined => {
  try {
    return xmlFormat(str, { throwOnFailure: true })
  } catch (e) {
    return undefined
  }
}

const onJSONViewerChange = (editor: Editor): void => {
  editor.getDoc().eachLine((line) => {
    if (line.text.includes('null')) {
      editor.addLineClass(line, 'text', 'null')
    }
  })
}

const JSONPanel: FC<JSONPanelProps> = ({
  event,
  isLoading,
  selectedTab,
  viewerData,
}) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <StretchToPageBottom className={styles.wrapper}>
        <div className={styles.jsonViewer} data-test-id="jsonPanel">
          <LoadingSpinner data-test-id="jsonViewerPanelLoading" />
        </div>
      </StretchToPageBottom>
    )
  }

  return (
    <StretchToPageBottom className={styles.wrapper}>
      <div
        className={cx(styles.jsonViewer, {
          [styles.defaultTextColor]: viewerData?.format !== 'json',
        })}
        data-test-id="jsonPanel"
      >
        <div className={styles.top}>
          <Text className={styles.textColor} size="sm">
            {t(
              `jsonViewerPage.json.description.${
                isApiEvent(event) ? 'api' : 'webhook'
              }.${selectedTab}`
            )}
          </Text>
          <CopyButton text={viewerData?.value} />
        </div>
        <CodeMirror
          value={viewerData?.value}
          options={{
            mode: {
              name: viewerData?.format === 'json' ? 'javascript' : '',
              json: viewerData?.format === 'json',
            },
            lineNumbers: true,
            readOnly: true,
            dragDrop: false,
            viewportMargin: Infinity,
            lineWrapping: true,
          }}
          onChange={onJSONViewerChange}
        />
      </div>
    </StretchToPageBottom>
  )
}

const PanelTabs: FC<PanelTabsProps> = ({
  onSelectedTabChange,
  selectedTab,
  showFirstTab,
  type,
  event,
  isLoading,
  viewerData,
}) => {
  const { t } = useTranslation()

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemColor: 'white',
            itemHoverColor: 'white',
            itemSelectedColor: 'white',
            itemActiveColor: 'white',
            inkBarColor: 'white',
          },
        },
      }}
    >
      <Tabs
        activeKey={selectedTab}
        onChange={(tab) => onSelectedTabChange(tab as TabKeys)}
        animated={false}
      >
        {showFirstTab && (
          <Tabs.TabPane
            tab={t(`jsonViewerPage.json.tabs.${type}.first`)}
            key="first"
          >
            <JSONPanel
              event={event}
              isLoading={isLoading}
              selectedTab="first"
              viewerData={viewerData}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={t(`jsonViewerPage.json.tabs.${type}.second`)}
          key="second"
        >
          <JSONPanel
            event={event}
            isLoading={isLoading}
            selectedTab="second"
            viewerData={viewerData}
          />
        </Tabs.TabPane>
      </Tabs>
    </ConfigProvider>
  )
}

const JSONViewer: FC<JSONViewerProps> = ({
  event,
  isLoading,
  onSelectedTabChange,
  selectedTab,
  text,
}) => {
  const { t } = useTranslation()
  const { isAdmin } = useStoreState((state) => state.user.currentUser)!

  const isSelectedEventApi = isApiEvent(event)

  const viewerData = useMemo((): ViewerData => {
    if (!text) {
      return {
        format: 'string',
        value: t('jsonViewerPage.json.notAvailable'),
      }
    }

    const json = getJsonString(text)
    if (json) {
      return {
        format: 'json',
        value: json,
      }
    }

    const xml = getXmlString(text)
    if (xml) {
      return {
        format: 'xml',
        value: xml,
      }
    }

    return {
      format: 'string',
      value: text,
    }
  }, [t, text])

  return (
    <section className={styles.container} data-test-id="jsonViewerContainer">
      <Text block className={styles.textColor} size="lg" weight="semibold">
        {t('jsonViewerPage.json.title')}
      </Text>
      <PanelTabs
        event={event}
        isLoading={isLoading}
        viewerData={viewerData}
        onSelectedTabChange={onSelectedTabChange}
        selectedTab={selectedTab}
        showFirstTab={isSelectedEventApi ? isAdmin : true}
        type={isSelectedEventApi ? 'api' : 'webhook'}
      />
    </section>
  )
}

export default JSONViewer
