import { FC } from 'react'
import {
  TEAM_STORE_GATEWAYS,
  TEAM_STORE_PROGRAMMING_LANG,
} from '@signifyd/http'
import { Alert, Divider, Select as AntSelect } from 'antd'
import { T3, Space } from '@signifyd/components'
import { FormikContext, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { joinClassNames } from '@signifyd/utils'
import Form, { FormItem, Input, Select } from '../Form'
import MobileAppSelect from './MobileAppSelect'
import { TeamFormValues } from './TeamForm.types'
import styles from './TeamForm.less'
import TranslatedLink from '../TranslatedLink/TranslatedLink'

const gatewayOptions = Object.values(TEAM_STORE_GATEWAYS)
const programingLangOptions = Object.values(TEAM_STORE_PROGRAMMING_LANG)

interface Props {
  teamFormik: FormikProps<TeamFormValues>
  editMode?: boolean
  isEditing?: boolean
}

/**
 * Renders the TeamForm given a teamFormik data instance
 */
const TeamForm: FC<Props> = ({
  editMode = false,
  isEditing = false,
  teamFormik,
}) => {
  const { t } = useTranslation()

  const selectedMobileApps = teamFormik.values.mobileAppPlatform ?? []

  const filteredMobileApps = selectedMobileApps.filter(
    (value) => value !== 'null'
  )

  return (
    <section data-test-id="teamCreationForm">
      <div>
        <FormikContext.Provider value={teamFormik}>
          <Form layout="vertical">
            {!editMode && (
              <>
                <T3>{t('teamForm.sectionOneTitle')}</T3>
                <Space size="md" />
              </>
            )}
            <FormItem
              name="storeName"
              label={t('teamForm.labels.storeName')}
              className={styles.formItem}
            >
              <Input
                disabled={editMode && !isEditing}
                name="storeName"
                data-test-id="storeNameInput"
                autoComplete="off"
                placeholder="Enter name here"
                required
              />
            </FormItem>
            <FormItem
              name="storeUrl"
              label={t('teamForm.labels.storeUrl')}
              className={styles.formItem}
            >
              <Input
                disabled={editMode && !isEditing}
                name="storeUrl"
                data-test-id="storeUrlInput"
                autoComplete="off"
                placeholder="https://"
                required
              />
            </FormItem>
            {!editMode && (
              <Alert
                message={t('teamForm.orderAlert.message')}
                description={t('teamForm.orderAlert.description')}
                type="info"
                showIcon
                className={styles.formAlert}
              />
            )}

            <Divider />
            {!editMode && (
              <>
                <T3>{t('teamForm.sectionTwoTitle')}</T3>
                <Space size="md" />
              </>
            )}

            <FormItem
              name="programmingLanguage"
              label={t('teamForm.labels.programmingLanguage')}
              className={styles.formItem}
            >
              <Select
                name="programmingLanguage"
                data-test-id="programmingLanguageSelect"
                disabled={editMode && !isEditing}
              >
                {programingLangOptions.map((option) => (
                  <AntSelect.Option key={option}>{option}</AntSelect.Option>
                ))}
              </Select>
            </FormItem>

            {!editMode && teamFormik.values.programmingLanguage === 'PHP' && (
              <Alert
                type="info"
                message={
                  <TranslatedLink
                    url="https://developer.signifyd.com/api/"
                    i18nKey="teamForm.phpAlert"
                  />
                }
                className={joinClassNames([
                  styles.formItemAlert,
                  styles.formAlert,
                ])}
              />
            )}

            <FormItem
              name="selectedPaymentGateway"
              label={t('teamForm.labels.selectedPaymentGateway')}
              className={styles.formItem}
            >
              <Select
                name="selectedPaymentGateway"
                data-test-id="paymentGatewaySelect"
                disabled={editMode && !isEditing}
              >
                {gatewayOptions.map((option) => (
                  <AntSelect.Option key={option}>{option}</AntSelect.Option>
                ))}
              </Select>
            </FormItem>

            {!editMode &&
              teamFormik.values.selectedPaymentGateway === 'Authorize.net' && (
                <Alert
                  type="info"
                  message={
                    <TranslatedLink
                      url="https://developer.signifyd.com/ecommerce-integration-guide/"
                      i18nKey="teamForm.authorizeDotNetAlert"
                    />
                  }
                  className={joinClassNames([
                    styles.formItemAlert,
                    styles.formAlert,
                  ])}
                />
              )}

            <FormItem
              name="mobileAppPlatform"
              label={t('teamForm.labels.mobileAppPlatform')}
              className={styles.formItem}
              data-test-id="mobileAppPlatformSelect"
            >
              <MobileAppSelect
                data-test-id="mobileAppPlatformSelect"
                disabled={editMode && !isEditing}
              />
            </FormItem>

            {!editMode && filteredMobileApps.length > 0 && (
              <Alert
                type="info"
                message={
                  <TranslatedLink
                    url="https://developer.signifyd.com/sdks/"
                    i18nKey="teamForm.mobilePlatformAlert"
                  />
                }
                className={joinClassNames([
                  styles.formItemAlert,
                  styles.formAlert,
                ])}
              />
            )}
          </Form>
        </FormikContext.Provider>
      </div>
    </section>
  )
}

export default TeamForm
