import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ExternalLink, SigTag, Space, Text } from '@signifyd/components'
import { colorJade, colorRed } from '@signifyd/colors'
import { INTEGRATION_ANALYSIS_WEBHOOK_DEVLIERY_STATUS } from '@signifyd/http'
import {
  getWebhookTopicDisplayName,
  getWebhookResponseCodeStatusText,
  isV3Webhook,
  WebhookEventSummary,
} from 'core/utils/integrationEvents/utils'
import styles from './WebhookStatus.less'

interface Props {
  webhook?: WebhookEventSummary
}

const WebhookStatus: FC<Props> = ({ webhook }) => {
  const { t } = useTranslation()

  if (!webhook) {
    return null
  }

  const isDeliverySuccess =
    webhook.lastWebhook.deliveryStatus ===
    INTEGRATION_ANALYSIS_WEBHOOK_DEVLIERY_STATUS.SUCCESS

  return (
    <div className={styles.panel} data-test-id="dataQualityPanel">
      <Text block className={styles.textColor} size="lg" weight="semibold">
        <span>{`${getWebhookTopicDisplayName(webhook.webhookTopic)}`}</span>
        {isV3Webhook(webhook) ? (
          <div>
            {t('jsonViewerPage.dataQuality.destinationUrl')}{' '}
            {webhook.destinationUrlIndex + 1}
          </div>
        ) : (
          <span> {t('jsonViewerPage.dataQuality.destinationUrl')}</span>
        )}
      </Text>
      <Space size="md" />
      <ExternalLink target="_blank" url={webhook.destinationUrl}>
        <Text block className={styles.webhookUrl} size="sm">
          {webhook.destinationUrl}
        </Text>
      </ExternalLink>
      <Space size="md" />
      <Text block className={styles.textColor} size="lg">
        {t('jsonViewerPage.dataQuality.webhookDeliverySummary')}
      </Text>
      <Space size="md" />
      <SigTag
        color={isDeliverySuccess ? colorJade : colorRed}
        type="primary"
        className={styles.tag}
      >
        <Text
          size="sm"
          className={styles.textColor}
          data-test-id="statusCodeText"
        >
          {getWebhookResponseCodeStatusText(
            webhook.lastWebhook.responseStatusCode
          )}
        </Text>
      </SigTag>
      <Space size="md" />
      {!isDeliverySuccess && (
        <>
          <div className={styles.webhookInfo} data-test-id="statusCode">
            <Text className={styles.textColor} size="sm">
              {t('jsonViewerPage.dataQuality.webhookStatusCode')}
            </Text>
            <Text className={styles.textColor} size="sm" weight="semibold">
              {webhook.lastWebhook.responseStatusCode}
            </Text>
          </div>
          <Space size="xs" />
        </>
      )}
      <div className={styles.webhookInfo} data-test-id="webhookTopic">
        <Text className={styles.textColor} size="sm">
          {t('jsonViewerPage.dataQuality.webhookSignifydTopic')}
        </Text>
        <Text className={styles.textColor} size="sm" weight="semibold">
          {getWebhookTopicDisplayName(webhook.webhookTopic)}
        </Text>
      </div>
      <Space size="xs" />
      <div className={styles.webhookInfo} data-test-id="hmacHeader">
        <Text className={styles.textColor} size="sm">
          {t('jsonViewerPage.dataQuality.webhookHMACHeader')}
        </Text>
        <Text className={styles.textColor} size="sm" weight="semibold">
          {webhook.lastWebhook.hmacHeader}
        </Text>
      </div>
    </div>
  )
}

export default WebhookStatus
