import { FC, useEffect, useState } from 'react'
import { Alert, Divider, Radio, RadioChangeEvent, Row } from 'antd'
import { INTEGRATION_ANALYSIS_ORDER_CHANNEL } from '@signifyd/http'
import { Space, P, Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useGetDataRangeResults } from 'pages/DataQualityPage/utils/flexibleSearch'
import {
  SearchPagination,
  SearchResultsTable,
} from 'pages/DataQualityPage/components/SearchResultsTable'
import { TotalResults } from 'pages/DataQualityPage/types'
import styles from './SearchResultsTableByRange.less'

interface Props {
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
  totalResultsPerRange: TotalResults
}

const SearchResultsTableByRange: FC<Props> = ({
  totalResultsPerRange,
  orderChannel,
}) => {
  const [rangeType, setRangeType] = useState<'rangeA' | 'rangeB'>()

  useEffect(() => {
    if (totalResultsPerRange.rangeA > 0) {
      setRangeType('rangeA')
    } else if (totalResultsPerRange.rangeB > 0) {
      setRangeType('rangeB')
    }
  }, [totalResultsPerRange.rangeA, totalResultsPerRange.rangeB])

  const { t } = useTranslation()

  const handleRangeChange = (e: RadioChangeEvent): void => {
    setRangeType(e.target.value)
  }

  const { rangeA: totalRangeA, rangeB: totalRangeB } = totalResultsPerRange

  if (!rangeType) {
    return null
  }

  if (totalResultsPerRange[rangeType] < 1) {
    return null
  }

  return (
    <>
      <Divider />
      <div className={styles.readOnlyMessage}>
        <Alert message={t('dataQualityPage.readOnly')} type="info" />
      </div>

      <div className={styles.tabButtons}>
        <Radio.Group onChange={handleRangeChange} value={rangeType}>
          {totalRangeA > 0 && (
            <Radio.Button value="rangeA">
              {t('dataQualityPage.dateRangeA')}
            </Radio.Button>
          )}
          {totalRangeB > 0 && (
            <Radio.Button value="rangeB">
              {t('dataQualityPage.dateRangeB')}
            </Radio.Button>
          )}
        </Radio.Group>
      </div>

      <Row justify="space-between" align="middle">
        <P>
          <Text weight="semibold" size="lg">
            {rangeType === 'rangeA'
              ? t('dataQualityPage.dateRangeA')
              : t('dataQualityPage.dateRangeB')}
          </Text>
        </P>
      </Row>

      <Row justify="space-between" align="middle">
        {t('dataQualityPage.totalRows', {
          count: totalResultsPerRange[rangeType],
        })}
      </Row>

      <SearchResultsTable
        orderChannel={orderChannel}
        rangeType={rangeType}
        dataFetcher={useGetDataRangeResults}
      />
      <Space size="md" />

      <Row justify="center" align="middle">
        <SearchPagination />
      </Row>
    </>
  )
}

export default SearchResultsTableByRange
