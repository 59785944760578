import { FC } from 'react'
import moment from 'moment-timezone'
import { useStoreState } from 'stores'

export interface DateProps {
  UTCTimestamp: string
  format?: string
  showTimeZoneAbbr?: boolean
  timeZone?: string
}

const FormatDate: FC<DateProps> = ({
  UTCTimestamp,
  format = 'MM-DD-YY',
  showTimeZoneAbbr = false,
  timeZone,
}) => {
  const currentUser = useStoreState((state) => state.user.currentUser)
  const userTimeZone = currentUser?.uiState?.timeZone?.name

  const localTime = moment
    .utc(UTCTimestamp)
    .tz(timeZone || userTimeZone || moment.tz.guess())
  const formattedTime = localTime.format(format)
  const zoneAbbr = showTimeZoneAbbr ? localTime.zoneAbbr() : ''

  return (
    <span>
      {UTCTimestamp ? formattedTime : ''}
      {zoneAbbr ? ` ${zoneAbbr}` : ''}
    </span>
  )
}

export default FormatDate
