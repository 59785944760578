import { FC } from 'react'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { colorGold } from '@signifyd/colors'
import styles from './AuthNetDisableModal.less'

interface Props {
  modalVisible: boolean
  modalOkFn: () => void
  modalCancelFn: () => void
}

const AuthNetDisableModal: FC<Props> = ({
  modalVisible,
  modalOkFn,
  modalCancelFn,
}) => (
  <Modal
    className={styles.disableModal}
    open={modalVisible}
    mask={false}
    closable={false}
    title={
      <>
        <ExclamationCircleTwoTone twoToneColor={colorGold} />
        Please confirm before proceeding
      </>
    }
    onOk={modalOkFn}
    onCancel={modalCancelFn}
    footer={[
      <Button
        key="back"
        type="link"
        style={{ float: 'left', marginLeft: '8px' }}
        onClick={modalCancelFn}
      >
        Cancel
      </Button>,
      <Button key="submit" type="link" loading={false} onClick={modalOkFn}>
        Disable
      </Button>,
    ]}
  >
    <p>
      Disabling Authorize.Net integration will stop syncing transaction details.
    </p>
  </Modal>
)

export default AuthNetDisableModal
