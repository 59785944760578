import {
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  IntegrationAnalysisResponse,
  IntegrationAnalysisSearchRequest,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
  SearchAggregationQuery,
  SearchAggregationResponse,
  SearchQuery,
  getInvestigationAggregates,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { RangeFilter } from '@signifyd/utils'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import { useGlobalFilterJoinType } from './useGlobalFilterJoinType'
import { useTableSorting } from './useTableSorting'

export type SearchResults = IntegrationAnalysisResponse & {
  searchRequest: Partial<IntegrationAnalysisSearchRequest>
}

export interface GraphDataResults {
  fields: Record<string, string | Array<string> | SEARCH_OPERATOR>
  filteredResults: number
}

export type UseGetSearchResultsResponse = Array<
  UseQueryResult<SearchAggregationResponse>
>

interface Params {
  rangeType: 'rangeA' | 'rangeB'
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
}

const getFilters = (): SearchAggregationQuery['filters'] => {
  const filters: SearchAggregationQuery['filters'] = {
    [SEARCH_FIELD.orderSessionId]: {
      query: {
        field: {
          fieldName: SEARCH_FIELD.orderSessionId,
          operator: SEARCH_OPERATOR.NOT_NULL,
        },
      },
    },
    [SEARCH_FIELD.deviceFingerprintingRequested]: {
      query: {
        field: {
          fieldName: SEARCH_FIELD.deviceFingerprintingRequested,
          operator: SEARCH_OPERATOR.EQUAL,
          values: ['true'],
        },
      },
    },
    [SEARCH_FIELD.deviceId]: {
      query: {
        field: {
          fieldName: SEARCH_FIELD.deviceId,
          operator: SEARCH_OPERATOR.NOT_NULL,
        },
      },
    },
    [SEARCH_FIELD.investigationId]: {
      query: {
        field: {
          fieldName: SEARCH_FIELD.investigationId,
          operator: SEARCH_OPERATOR.NOT_NULL,
        },
      },
    },
  }

  return filters
}

const getDeviceFingerprintingSearchQuery = (
  filters: {
    teamId: Array<string>
    normalizedPurchaseCreatedAt: RangeFilter | null
  },
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
): SearchQuery => {
  const defaultParams: Array<SearchQuery> = [
    {
      field: {
        fieldName: SEARCH_FIELD.normalizedPurchaseCreatedAt,
        operator: SEARCH_OPERATOR.RANGE,
        values: [
          filters.normalizedPurchaseCreatedAt!.min!,
          filters.normalizedPurchaseCreatedAt!.max!,
        ],
      },
    },
    {
      field: {
        fieldName: SEARCH_FIELD.teamId,
        operator: SEARCH_OPERATOR.IN,
        values: filters.teamId,
      },
    },
  ]

  if (orderChannel) {
    const orderChannelParams: Array<SearchQuery> = [
      {
        field: {
          fieldName: SEARCH_FIELD.orderChannel,
          operator: SEARCH_OPERATOR.EQUAL,
          values: orderChannel,
        },
      },
    ]

    return { and: [...defaultParams, ...orderChannelParams] }
  }

  return { and: [...defaultParams] }
}

const useGetAggregateRangeDataPerRange = ({
  rangeType = 'rangeA',
  orderChannel,
}: Params): UseQueryResult<SearchAggregationResponse> => {
  const {
    query: { teamId, [rangeType]: range },
  } = useDataComparisonQueryParams()

  const filters = {
    teamId,
    normalizedPurchaseCreatedAt: range.normalizedPurchaseCreatedAt,
  }

  const {
    query: { sortOrder, orderBy },
  } = useTableSorting()

  const { joinType } = useGlobalFilterJoinType()

  return useQuery({
    queryKey: [
      'aggregateDataForGraphs',
      filters,
      sortOrder,
      orderBy,
      joinType,
      rangeType,
      orderChannel,
      range,
    ],
    queryFn: async () => {
      const searchQuery: SearchAggregationQuery = {
        query: getDeviceFingerprintingSearchQuery(filters, orderChannel),
        filters: getFilters(),
        fields: {},
      }

      const { data } = await getInvestigationAggregates(searchQuery)

      return data
    },
    enabled:
      !!joinType && !!filters.teamId && !!range.normalizedPurchaseCreatedAt,
  })
}

export default useGetAggregateRangeDataPerRange
