export default {
  header: {
    productName: 'Developer Tools',
    pageTitle: 'API events & JSON',
    caseId: 'Case ID/Signifyd ID: {{caseId}}',
    orderId: 'Order ID: {{orderId}}',
    viewInConsole: 'View in console',
  },
  api: '{{api}}',
  apiCalls:
    'Most recent {{api}} call: {{- time}} | Total {{api}} calls: {{calls}}',
  webhooks: 'Webhooks',
  webhookCalls:
    'Most recent {{- topic}} Webhook: {{- time}} | Total Webhooks: {{calls}}',
  checkoutId: 'Checkout ID\n{{checkoutId}}',
  json: {
    title: 'JSON',
    description: {
      api: {
        first: 'Saved request is what was received by Signifyd',
        second: 'Raw request is what was sent to Signifyd',
      },
      webhook: {
        first: 'Request is what Signifyd sent',
        second: 'Response is what was sent back',
      },
    },
    tabs: {
      api: {
        first: 'Saved request',
        second: 'Raw request',
      },
      webhook: {
        first: 'Request',
        second: 'Response',
      },
    },
    notAvailable: 'Not available',
  },
  copyButton: {
    copy: 'Copy JSON',
    copied: 'Copied!',
    toast: 'JSON copied',
  },
  refreshButton: 'Refresh',
  dataQuality: {
    fieldQualityTitle: 'Field quality',
    missingFields: {
      action: 'The fields below require attention.',
      label: '{{count}} expected fields missing',
      description:
        "Missing fields are expected fields that are missing in the raw request or sent with empty or null values.\n\nWhy they're missing\n• The field is not provided in the request\n• The wrong field name is being used\n• The field is not in the appropriate JSON object",
    },
    allFieldsPresent: {
      action: 'No fields require attention.',
      description: 'Expected fields are fields needed for the integration',
      label: 'Expected fields present',
      criticalTitle: 'Critical Fields',
      importantTitle: 'Important Fields',
    },
    destinationUrl: 'destination URL',
    webhookDeliverySummary: 'Delivery summary',
    webhookStatusCode: 'Status code',
    webhookSignifydTopic: 'Signifyd topic',
    webhookHMACHeader: 'HMAC header',
    webhookResponseCodeDescription: 'Delivery failure',
    webhookResponseCodeDescription_200: 'Delivery success',
    webhookResponseCodeDescription_400: 'Bad request',
    webhookResponseCodeDescription_401: 'Unauthorised',
    webhookResponseCodeDescription_403: 'Forbidden',
    webhookResponseCodeDescription_404: 'Not found',
    webhookResponseCodeDescription_500: 'Internal server error code',
    webhookResponseCodeDescription_504: 'Gateway not found',
    webhookV3Display: 'Webhook URL {{number}}',
    webhookV2Display: 'Webhook URL',
  },
  errorModal: {
    title: 'Uh oh! We couldn’t find a case with that id',
    message: 'Check your URL or contact your Signifyd admin to gain access.',
  },
}
