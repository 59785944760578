export default {
  title: 'Resources',
  integration: {
    title: 'Integration Guide',
    subTitle: 'A quick start guide on how to build your integration.',
    description: {
      preLinkText: 'Open the',
      linkText: 'Signifyd Integration Guide',
      postLinkText: `for a complete resource of integration steps and API responses list. 
        The guide is complementary to the in-app Teams and Data Quality tools found in Signifyd Developer.`,
    },
  },
  enterprise: {
    title: 'Signifyd Enterprise APIs',
    subTitle: 'Our synchronous response solution for Enterprise.',
    description: {
      preLinkText:
        'Signifyd Enterprise APIs bundles a synchronous Create Case, Chargebacks and Merchant Reporting in one offering. Visit the',
      linkText: 'Signifyd Enterprise APIs',
      postLinkText: 'documentation to explore the details.',
    },
  },
  teamOverview: {
    title: 'Overview of Teams',
    description: {
      normalText:
        'A completed integration consists of two teams. To start an integration, you only need to create a',
      boldText: 'Test Team!',
    },
    altText: `Process overview for going live with Signifyd. 
    The first step is to create a Test Team and integrate into your sandbox environment. 
    You can then validate the Data Quality in our Developer Tools. 
    Signifyd will then do a model calibration and create a Production team for you. 
    Once your production site is integrated, you are ready to go live.`,
  },
  quickLinks: {
    title: 'Quick Links',
    subTitle: 'Signifyd Developer Center',
    description: {
      preLinkText: 'Visit',
      linkText: 'developer.signifyd.com',
      postLinkText:
        'for guides, tutorials, and documention to help you every step of the way.',
    },
  },
}
