import { FC } from 'react'
import { IntegrationAnalysisOrder } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'

interface Props {
  rowData: IntegrationAnalysisOrder
}

const DateTimeCell: FC<Props> = ({ rowData }) => {
  // Both normalizedPurchaseCreatedAt and purchaseCreatedAt are UTC string
  // Example: 2017-09-08T17:15:01+0000
  const date = rowData.purchaseCreatedAt

  if (!date) {
    return null
  }

  return (
    <>
      <FormatDate
        UTCTimestamp={date}
        format="MM/DD/YYYY h:mmA"
        timeZone="GMT"
      />
      {' GMT'}
    </>
  )
}

export default DateTimeCell
