import { FC, useState } from 'react'
import { P, Text, Space } from '@signifyd/components'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import TeamForm, { useTeamFormik } from 'core/components/TeamForm'
import { EnhancedUserTeam } from 'core/types'

interface Props {
  team: EnhancedUserTeam
}

const EditTeamFormContainer: FC<Props> = ({ team }) => {
  const { t } = useTranslation()

  const teamFormik = useTeamFormik({
    onlineStore: team.onlineStore,
    teamId: team.teamId,
    editMode: true,
  })
  const [isEditing, setIsEditing] = useState(false)

  const onClickCancel = (): void => {
    setIsEditing(false)
    teamFormik.resetForm()
  }

  const onClickSave = async (): Promise<void> => {
    await teamFormik.submitForm()

    setIsEditing(false)
  }

  return (
    <section>
      <Text size="lg">{t('settingsPage.editTeamContainer.title')}</Text>
      <Space size="sm" />
      <P>{t('settingsPage.editTeamContainer.description')}</P>
      <Space size="sm" />
      <div>
        {isEditing ? (
          <div>
            <Button
              onClick={onClickCancel}
              style={{ marginRight: '16px' }}
              data-test-id="cancelButton"
            >
              {t('settingsPage.editTeamContainer.cancelButton')}
            </Button>
            <Button
              type="primary"
              onClick={onClickSave}
              data-test-id="saveButton"
            >
              {t('settingsPage.editTeamContainer.submitButton')}
            </Button>
          </div>
        ) : (
          <Button onClick={() => setIsEditing(true)} data-test-id="editButton">
            {t('settingsPage.editTeamContainer.editButton')}
          </Button>
        )}
      </div>
      <Space size="md" />
      <TeamForm editMode teamFormik={teamFormik} isEditing={isEditing} />
    </section>
  )
}

export default EditTeamFormContainer
