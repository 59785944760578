import { unset } from 'lodash'
import {
  getInvestigationJSONData,
  getInvestigationRequests,
  IntegrationAnalysisEventType,
  USER_ROLE,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { JSONViewerFieldsToOmit } from 'core/constants'
import { useStoreState } from 'stores'

export type UseGetJsonResult = UseQueryResult<string>

const removeAdminOnlyFields = (json: JSON, roles: Array<USER_ROLE>): JSON => {
  JSONViewerFieldsToOmit.forEach((field) => {
    if (
      typeof field === 'object' &&
      !field.exceptionRoles.some((role) => roles.includes(role))
    ) {
      unset(json, field.fieldName)
    } else if (typeof field === 'string') {
      unset(json, field)
    }
  })

  return json
}

export const useGetJson = (
  signifydId?: number,
  investigationId?: number,
  eventType?: IntegrationAnalysisEventType,
  normalized = false
): UseGetJsonResult => {
  const { isAdmin, roles } = useStoreState((state) => state.user.currentUser)!

  const getInvestigationJson = async (): Promise<string> => {
    const { data } = await getInvestigationJSONData(
      investigationId!,
      normalized
    )

    if (!isAdmin) {
      removeAdminOnlyFields(data, roles)
    }

    return JSON.stringify(data, null, 2)
  }

  const getInvestigationRequestJson = async (): Promise<string> => {
    const { data } = await getInvestigationRequests(
      signifydId!,
      eventType!,
      normalized ?? false
    )

    return JSON.stringify(data, null, 2)
  }

  const useQueryResponse = useQuery(
    ['getJson', signifydId, eventType, normalized],
    async () => {
      if (eventType === 'CreateInvestigationRequest') {
        return getInvestigationJson()
      }

      return getInvestigationRequestJson()
    },
    { enabled: !!signifydId && !!eventType }
  )

  return useQueryResponse
}

export default useGetJson
