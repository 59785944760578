import { FC, ReactElement } from 'react'
import cx from 'classnames'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import styles from './YesNoCell.less'

export interface YesNoCellProps {
  showYes: boolean
  tooltip?: ReactElement
}

const YesNoCell: FC<YesNoCellProps> = ({ showYes, tooltip }) => {
  const translationKey = 'dataQualityPage.yesNoValues'

  const { t } = useTranslation()

  if (showYes) {
    return (
      <div
        data-test-id="valueYes"
        className={cx(styles.fullBgWrapper, styles.deviceProfiling)}
      >
        <Text>{t(`${translationKey}.true`)}</Text>
      </div>
    )
  }

  return (
    <div
      data-test-id="valueNo"
      className={cx(
        styles.fullBgWrapper,
        styles.deviceProfiling,
        styles.noValue
      )}
    >
      <Text>{t(`${translationKey}.false`)}</Text>
      {tooltip}
    </div>
  )
}

export default YesNoCell
