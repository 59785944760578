import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import {
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  SEARCH_FIELD,
} from '@signifyd/http'
import GraphCard from 'pages/DataQualityPage/components/GraphCard'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import { calculateMessage } from 'pages/DataQualityPage/components/DeviceProfilingSummary/utils'
import { useGetAggregateRangeDataPerRange } from 'pages/DataQualityPage/utils/flexibleSearch'
import {
  calculateThresholdMap,
  getFieldData,
} from 'pages/DataQualityPage/components/DataComparisonTool/utils'
import BarGraph from 'pages/DataQualityPage/components/BarGraph/BarGraph'
import styles from './DeviceProfilingSummary.less'

export type CalculationMethod = 'percentage' | 'count'

export interface GraphData {
  date: string
  percentage: number
}

interface Props {
  calculationMethod: CalculationMethod
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
}

const graphFields = [
  SEARCH_FIELD.orderSessionId,
  SEARCH_FIELD.deviceFingerprintingRequested,
  SEARCH_FIELD.deviceId,
]

const DeviceProfilingSummary: FC<Props> = ({
  calculationMethod,
  orderChannel,
}) => {
  const { t } = useTranslation()

  const {
    query: { rangeB: rangeBQuery },
  } = useDataComparisonQueryParams()

  const { data: resultsA } = useGetAggregateRangeDataPerRange({
    rangeType: 'rangeA',
    orderChannel,
  })

  const { data: resultsB } = useGetAggregateRangeDataPerRange({
    rangeType: 'rangeB',
    orderChannel,
  })

  const combinedData = {
    rangeA: {
      ...getFieldData(resultsA),
    },
    rangeB: {
      ...getFieldData(resultsB),
    },
  }

  const { rangeA, rangeB } = combinedData

  const fieldsThresholdsMap = calculateThresholdMap(resultsA, resultsB)

  const currentRange =
    rangeBQuery.normalizedPurchaseCreatedAt !== undefined
      ? t('dataQualityPage.dateRangeB')
      : t('dataQualityPage.dateRangeA')

  const totalResultsRangeA = resultsA?.filters.investigationId?.count ?? 0
  const totalResultsRangeB = resultsB?.filters.investigationId?.count ?? 0

  return (
    <>
      <div className={styles.deviceProfilingGraphsContainer}>
        {graphFields.map((graphField) => {
          const { status } = fieldsThresholdsMap[graphField]

          return (
            <GraphCard
              key={graphField}
              headerText={t(`dataQualityPage.bannerLabels.${graphField}`)}
              footer={
                <div className={styles.footerText}>
                  {calculateMessage(
                    graphField,
                    {
                      rangeA: totalResultsRangeA,
                      rangeB: totalResultsRangeB,
                    },
                    combinedData
                  )}
                  <Tooltip
                    title={t(`dataQualityPage.graphFooterTooltip`, {
                      currentRange,
                    })}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }
              footerType={status}
            >
              <BarGraph
                valuesPerRange={{
                  rangeA: rangeA[graphField],
                  rangeB: rangeB[graphField],
                }}
                calculationMethod={calculationMethod}
                totalResults={{
                  rangeA: totalResultsRangeA,
                  rangeB: totalResultsRangeB,
                }}
              />
            </GraphCard>
          )
        })}
      </div>
    </>
  )
}

export default DeviceProfilingSummary
