import { CalculationMethod, TotalResults } from 'pages/DataQualityPage/types'

export const getNumberOrZero = (number: number): number => {
  if (Number.isNaN(number)) {
    return 0
  }

  return number
}

export const getFormattedValue = (
  value: number | null,
  calculationMethod: CalculationMethod
): string => {
  if (calculationMethod === 'percentage') {
    return `${value}%`
  }

  return `${value}`
}

export const getGridValues = (
  calculationMethod: CalculationMethod,
  totalResults: TotalResults
): [number, number, number] => {
  if (calculationMethod === 'percentage') {
    return [0, 50, 100]
  }

  const highest = Math.max(totalResults.rangeA, totalResults.rangeB)

  return [0, Math.round(highest / 2), highest]
}

export const calculateValue = (
  totalResults: number,
  filteredResults: number,
  calculationMethod: CalculationMethod
): number => {
  if (Number.isNaN(filteredResults) || Number.isNaN(totalResults)) {
    return 0
  }

  if (calculationMethod !== 'percentage') {
    return filteredResults
  }

  const calculatedPercentage = (filteredResults / totalResults) * 100

  const percentage = !Number.isNaN(calculatedPercentage)
    ? calculatedPercentage
    : 0

  return Number(percentage.toFixed(2))
}

export const shouldDisplayDiff = (valueB: number, valueA: number): boolean => {
  return valueB - valueA !== 0
}

export const isImproving = (valueB: number, valueA: number): boolean => {
  const result = getNumberOrZero(valueB) - getNumberOrZero(valueA)

  return result > 0
}

export const calculateDiffValue = (
  valueB: number,
  valueA: number,
  calculationMethod: CalculationMethod
): number => {
  if (calculationMethod === 'percentage') {
    return Number((valueB - valueA).toFixed(2))
  }

  return valueB - valueA
}
