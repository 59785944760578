import {
  CHECKPOINT_ACTION,
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
} from '@signifyd/http'

export const orderChannel: Record<INTEGRATION_ANALYSIS_ORDER_CHANNEL, string> =
  {
    IN_STORE_KIOSK: 'In store kiosk',
    MARKETPLACE: 'Marketplace',
    MOBILE_APP: 'Mobile app',
    PHONE: 'Phone',
    SCAN_AND_GO: 'Scan & go',
    SOCIAL: 'Social',
    WEB: 'Web',
    SMART_TV: 'Smart TV',
    MIT: 'Mit',
  }

export const checkpointAction: Record<CHECKPOINT_ACTION, string> = {
  HOLD: 'Hold',
  CREDIT: 'Credit',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  CHALLENGE: 'Challenge',
}
