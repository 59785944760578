import { isAxiosError } from 'axios'

export interface SigErrorData {
  messages?: Array<string>
  errors?: Record<string, Array<string>>
}

const getSigErrorMessage = (error?: unknown): string | undefined => {
  if (!isAxiosError<SigErrorData>(error)) {
    return undefined
  }

  const data = error?.response?.data
  const errors = Object.values(data?.errors ?? {}).flat()

  // Errors can be returned in either a "messages" array or an "errors" object keyed
  // on the offending field. Prefer the value found in "errors" otherwise fall back
  // to the first value in "messages"
  return errors?.[0] || data?.messages?.[0]
}

export default getSigErrorMessage
