import { useQueryParams, SetQuery } from 'use-query-params'
import {
  getSearchQueryParams,
  DecodedSearchParams,
  SearchParamsMap,
  getDefaultSearchRange,
} from '@signifyd/utils'
import { SEARCH_FIELD } from '@signifyd/http'

const hasUserAppliedColumnFilters = (filters: DecodedSearchParams): boolean => {
  const DEFAULT_FILTERS = [
    SEARCH_FIELD.teamId,
    SEARCH_FIELD.normalizedPurchaseCreatedAt,
  ]

  const appliedFilters = Object.entries(filters)
    .filter(([key, value]) => {
      if (DEFAULT_FILTERS.includes(key as SEARCH_FIELD)) {
        return false
      }

      if (Array.isArray(value)) {
        return !!value.length
      }

      return !!value
    })
    .map(([key]) => key) as Array<SEARCH_FIELD>

  return !!appliedFilters.length
}

interface UseTableQueryParams {
  query: DecodedSearchParams
  setQuery: SetQuery<SearchParamsMap>
  resetTableFilters: () => void
  userHasAppliedColumnFilters: boolean
}

export const useTableQueryParams = (): UseTableQueryParams => {
  const [query, setQuery] = useQueryParams(getSearchQueryParams())

  const resetTableFilters = (): void => {
    const clearedFilters: Partial<DecodedSearchParams> = {}

    Object.keys(query).forEach((key) => {
      clearedFilters[key as keyof DecodedSearchParams] = undefined
    })

    const selectedTeam = query.teamId?.[0]?.toString()

    setQuery({
      ...clearedFilters,
      teamId: selectedTeam ? [selectedTeam] : undefined,
      normalizedPurchaseCreatedAt: getDefaultSearchRange(),
    })
  }

  return {
    query,
    setQuery,
    resetTableFilters,
    userHasAppliedColumnFilters: hasUserAppliedColumnFilters(query),
  }
}
