import { SearchParamsMap } from '@signifyd/utils'
import { SetQuery } from 'use-query-params'
import { useTableQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch'

const useGetSelectedTeams = (): {
  selectedTeams: Array<string>
  setSelectedTeams: SetQuery<SearchParamsMap>
} => {
  const { query, setQuery } = useTableQueryParams()

  return {
    selectedTeams: (query.teamId ?? []).filter(
      (value) => value
    ) as Array<string>,
    setSelectedTeams: setQuery,
  }
}

export default useGetSelectedTeams
