import { FC, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { message, Tabs } from 'antd'
import { Layout, Space, StretchToPageBottom } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStoreActions } from 'stores'
import LoadingSpinner from 'core/components/LoadingSpinner'
import PageWrapper from 'core/components/PageWrapper'
import TeamStatusTag from 'pages/TeamsPage/components/TeamCard/TeamStatusTag'
import useGetEnhancedUserTeams from 'core/queries/useGetEnhancedUserTeams'
import { navigateToTeams } from 'core/utils/teams/utils'
import useGetSelectedTeams from 'core/utils/useGetSelectedTeams'
import WebhooksContainer from './containers/WebhooksContainer'
import DeleteTeamContainer from './containers/DeleteTeamContainer'
import IntegrationsContainer from './containers/IntegrationsContainer'
import styles from './SettingsPage.less'
import EditTeamFormContainer from './containers/EditTeamFormContainer'

const { Header } = Layout

const { TabPane } = Tabs

const ContentWrapper: FC = ({ children }) => {
  return (
    <StretchToPageBottom className={styles.contentWrapper}>
      <div className={styles.box}>{children}</div>
    </StretchToPageBottom>
  )
}

const SettingsPage: FC = () => {
  const { teamId } = useParams()
  const { pathname } = useLocation()
  const { selectedTeams } = useGetSelectedTeams()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const {
    data: enhancedUserTeams,
    isLoading: enhancedUserTeamsLoading,
    isFetching: enhancedUserTeamsFetching,
  } = useGetEnhancedUserTeams([teamId])

  const selectedTeamsQueryString = `?teamId=${selectedTeams.join('_')}`

  const { setGateways } = useStoreActions((actions) => actions.integrations)

  useEffect(() => {
    return () => {
      setGateways(null)
    }
  }, [setGateways])

  const currentTeam = useMemo(() => {
    return enhancedUserTeams?.find(({ teamId: id }) => id === Number(teamId))
  }, [enhancedUserTeams, teamId])

  if (!enhancedUserTeamsLoading && !!enhancedUserTeams && !currentTeam) {
    message.warning(t('settingsPage.invalidTeamWarning'))
    navigateToTeams({ navigate, selectedTeams })
  }

  const loading =
    !enhancedUserTeams || enhancedUserTeamsLoading || enhancedUserTeamsFetching

  return (
    <PageWrapper
      Header={
        <>
          <Header marginBottom="none" paddingBottom="none">
            <Link to={`/teams${selectedTeamsQueryString}`}>
              <div className={styles.nav}>
                <ArrowLeftOutlined className={styles.icon} />
                <span>{t('settingsPage.header.backNavigation')}</span>
              </div>
            </Link>
          </Header>
          <Header
            title={
              currentTeam && (
                <div className={styles.title}>
                  <span>{currentTeam?.onlineStore?.storeName}</span>
                  <TeamStatusTag team={currentTeam} />
                </div>
              )
            }
            innerMinWidth={null}
            extra="Settings"
            marginBottom="sm"
            paddingBottom="none"
          />
        </>
      }
    >
      <section data-test-id="settings-page">
        <Space size="sm" />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Tabs
            activeKey={
              pathname
                ? `${pathname}${selectedTeamsQueryString}`
                : `/settings/${teamId}/details${selectedTeamsQueryString}`
            }
            tabBarStyle={{ marginBottom: '32px' }}
          >
            <TabPane
              key={`/settings/${teamId}/details${selectedTeamsQueryString}`}
              tab={
                <Link
                  to={`/settings/${teamId}/details${selectedTeamsQueryString}`}
                >
                  {t('settingsPage.tabs.teamDetails')}
                </Link>
              }
            >
              <ContentWrapper>
                <EditTeamFormContainer team={currentTeam!} />
              </ContentWrapper>
            </TabPane>
            <TabPane
              key={`/settings/${teamId}/webhooks${selectedTeamsQueryString}`}
              tab={
                <Link
                  to={`/settings/${teamId}/webhooks${selectedTeamsQueryString}`}
                >
                  {t('settingsPage.tabs.webhooks')}
                </Link>
              }
            >
              <ContentWrapper>
                <WebhooksContainer teamId={Number(teamId)} />
              </ContentWrapper>
            </TabPane>
            <TabPane
              key={`/settings/${teamId}/integrations${selectedTeamsQueryString}`}
              tab={
                <Link
                  to={`/settings/${teamId}/integrations${selectedTeamsQueryString}`}
                >
                  {t('settingsPage.tabs.integrations')}
                </Link>
              }
            >
              <ContentWrapper>
                <IntegrationsContainer teamId={Number(teamId)} />
              </ContentWrapper>
            </TabPane>
            <TabPane
              key={`/settings/${teamId}/delete${selectedTeamsQueryString}`}
              tab={
                <Link
                  to={`/settings/${teamId}/delete${selectedTeamsQueryString}`}
                >
                  {t('settingsPage.tabs.deleteTeam')}
                </Link>
              }
            >
              {currentTeam && (
                <ContentWrapper>
                  <DeleteTeamContainer
                    teamId={Number(teamId)}
                    teamName={currentTeam.teamName}
                    selectedTeams={selectedTeams}
                  />
                </ContentWrapper>
              )}
            </TabPane>
          </Tabs>
        )}
      </section>
    </PageWrapper>
  )
}

export default SettingsPage
