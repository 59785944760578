import { FC } from 'react'
import { useDataQualityContext } from 'pages/DataQualityPage/DataQualityProvider'
import { COL_FILTERS, ColFilterKey } from '../columns/ColumnFilterConfig'
import SearchColumnFilter, {
  PopoverProps,
} from '../ColumnFilterPopover/ColumnFilterPopover'
import SearchOrderIcon from '../SearchOrderIcon/SearchOrderIcon'
import styles from './SearchTableHeader.less'

export interface TableHeaderProps {
  title: string
  columnKey: ColFilterKey
  popoverOptions?: PopoverProps
}

const canOrder = (columnKey: ColFilterKey): boolean => {
  const columnData = COL_FILTERS[columnKey]

  if (!columnData) {
    return false
  }

  if (columnData.searchKey === 'custom') {
    return false
  }

  return !!columnData.canOrderBy
}

const SearchTableHeader: FC<TableHeaderProps> = ({
  title,
  columnKey,
  popoverOptions,
}) => {
  const { isFilterEnabled } = useDataQualityContext()

  const hasOrderIcon = columnKey && canOrder(columnKey)
  const hasPopover = columnKey && COL_FILTERS[columnKey]?.TYPE

  return (
    <div className={styles.tableHeader}>
      <span>{title}</span>
      <div className={styles.iconWrapper}>
        {hasOrderIcon && <SearchOrderIcon columnKey={columnKey} />}
        {isFilterEnabled && hasPopover && (
          <SearchColumnFilter columnKey={columnKey} {...popoverOptions} />
        )}
      </div>
    </div>
  )
}

export default SearchTableHeader
