import { checkpointAction, orderChannel } from './enums'

export default {
  resetButton: 'Reset',
  applyButton: 'Apply',
  inputs: {
    textInput: {
      placeholder: 'Search',
    },
    multiselect: {
      orderChannel: { ...orderChannel },
      checkpointAction: { ...checkpointAction },
      deviceFingerprintingRequested: {
        true: 'Yes',
        false: 'No',
      },
    },
  },
}
