import { FC, ReactNode, ComponentType } from 'react'
import { IconProps, StretchToPageBottom } from '@signifyd/components'
import NoResults from 'core/components/NoResults'
import styles from './PagePlaceholder.less'

interface Props {
  icon?: ComponentType<IconProps>
  title: ReactNode
  description?: ReactNode
  marginBottom?: number
  ['data-test-id']?: string
}

const DEFAULT_MARGIN_BOTTOM = 48

const PagePlaceholder: FC<Props> = ({
  icon,
  title,
  description = '',
  marginBottom = DEFAULT_MARGIN_BOTTOM,
  'data-test-id': dataTestId,
}) => (
  <StretchToPageBottom
    className={styles.wrapper}
    centerChildren
    marginBottom={marginBottom}
  >
    <NoResults
      data-test-id={dataTestId}
      icon={icon}
      title={title}
      description={description}
    />
  </StretchToPageBottom>
)

export default PagePlaceholder
