import {
  IntegrationAnalysisIncomingEvent,
  IntegrationAnalysisOrder,
  IntegrationAnalysisWebhookSummary,
  INTEGRATION_ANALYSIS_WEBHOOK_TOPIC,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import moment from 'moment-timezone'

export type WebhookEventSummary = IntegrationAnalysisWebhookSummary & {
  webhookTopic?: INTEGRATION_ANALYSIS_WEBHOOK_TOPIC
  destinationUrlIndex: number
}

export type SelectedEvent =
  | IntegrationAnalysisIncomingEvent
  | WebhookEventSummary

export const isApiEvent = (
  event?: IntegrationAnalysisIncomingEvent | WebhookEventSummary
): event is IntegrationAnalysisIncomingEvent => {
  return (event as IntegrationAnalysisIncomingEvent)?.eventType !== undefined
}

export const isWebhookEvent = (
  event?: IntegrationAnalysisIncomingEvent | WebhookEventSummary
): event is WebhookEventSummary => {
  return (event as WebhookEventSummary)?.webhookTopic !== undefined
}

export const isV3Webhook = (webhook: WebhookEventSummary): boolean => {
  return webhook.lastWebhook.checkpoint != null
}

export const getDocumentationUrl = (event: SelectedEvent): string => {
  if (isApiEvent(event)) {
    return event.eventName === 'CreateCase'
      ? 'https://developer.signifyd.com/api-v2/#/reference/cases'
      : `https://docs.signifyd.com/#operation/${event.eventName}`
  }

  if (isWebhookEvent(event)) {
    return 'https://docs.signifyd.com/#tag/WebhookEvents'
  }

  return ''
}

export const getEventDisplayName = (event: SelectedEvent): string => {
  if (isWebhookEvent(event)) {
    return 'Webhooks'
  }

  const eventDisplayNames = {
    Checkout: 'Checkout API',
    CreateCase: 'Create Case',
    Fulfillment: 'Fulfillment API',
    Sale: 'Sale API',
    Transaction: 'Transaction API',
  }

  return eventDisplayNames[event.eventName]
}

export const getWebhookTopicDisplayName = (
  webhookTopic?: INTEGRATION_ANALYSIS_WEBHOOK_TOPIC
): string | undefined => {
  if (!webhookTopic) {
    return undefined
  }

  const v2WebhookDisplayNames: Record<
    INTEGRATION_ANALYSIS_WEBHOOK_TOPIC,
    string | undefined
  > = {
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.CASE_CREATION]: 'cases/creation',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.CASE_RESCORE]: 'cases/rescore',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.CASE_REVIEW]: 'cases/review',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.CLAIM_PAYOUT]: 'claims/paid',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.CLAIM_REVIEWED]: 'claim/reviewed',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.DECISION_MADE]: 'decisions/*',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.GUARANTEE_COMPLETION]:
      'guarantees/completion',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.GUARANTEE_INELIGIBLE]:
      'guarantees/ineligible',
    [INTEGRATION_ANALYSIS_WEBHOOK_TOPIC.ORDER_CHECKPOINT_ACTION_UPDATE]:
      undefined,
  }

  return v2WebhookDisplayNames[webhookTopic] ?? webhookTopic
}

export const getWebhookResponseCodeStatusText = (
  responseCode?: number
): string =>
  i18nInstance.t('jsonViewerPage.dataQuality.webhookResponseCodeDescription', {
    context: responseCode?.toString(),
  })

export const getEvents = (
  selectedCase: IntegrationAnalysisOrder
): Array<SelectedEvent> => {
  const events: Array<SelectedEvent> = []

  selectedCase.incomingEvents?.forEach((incomingEvent) => {
    events.push(incomingEvent)
  })
  selectedCase.webhooks?.forEach((webhook) => {
    webhook.webhookSummaryByUrl?.forEach((webhookSummary, index) => {
      events.push({
        ...webhookSummary,
        webhookTopic: webhook.webhookTopic,
        destinationUrlIndex: index,
      })
    })
  })

  return events
}

export const groupWebhooks = (
  webhookSummaries: Array<WebhookEventSummary | undefined>
): Record<INTEGRATION_ANALYSIS_WEBHOOK_TOPIC, Array<WebhookEventSummary>> => {
  const groupedWebhooks: Record<
    INTEGRATION_ANALYSIS_WEBHOOK_TOPIC,
    Array<WebhookEventSummary>
  > = {
    DECISION_MADE: [],
    CASE_REVIEW: [],
    CASE_CREATION: [],
    CASE_RESCORE: [],
    CLAIM_PAYOUT: [],
    CLAIM_REVIEWED: [],
    GUARANTEE_COMPLETION: [],
    GUARANTEE_INELIGIBLE: [],
    ORDER_CHECKPOINT_ACTION_UPDATE: [],
  }

  webhookSummaries.forEach((webhookSummary) => {
    if (!webhookSummary?.webhookTopic) {
      return
    }

    groupedWebhooks[webhookSummary?.webhookTopic].push(webhookSummary)
  })

  const filteredGroupedWebhooks = Object.fromEntries(
    Object.entries(groupedWebhooks).filter(([, value]) => value.length > 0)
  ) as Record<INTEGRATION_ANALYSIS_WEBHOOK_TOPIC, Array<WebhookEventSummary>>

  return filteredGroupedWebhooks
}

export const getFormattedDate = (timestamp: string): string => {
  return moment.utc(timestamp).format('MM/DD/YY HH:mm:ss z')
}
