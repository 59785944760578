import React from 'react'
import ReactDOM, { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import { AuthProvider } from '@signifyd/components'
import store from 'stores/index'
import UserLoader from 'UserLoader'
import App from './App'
import 'antd/dist/reset.css'
import '@signifyd/ant/dist/overrides/index.less'

import './index.less'

if (process.env.NODE_ENV === 'development') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {}, '#dev-tools-app')
  })
}

render(
  <AuthProvider>
    <StoreProvider store={store}>
      <UserLoader>
        <App />
      </UserLoader>
    </StoreProvider>
  </AuthProvider>,
  document.getElementById('dev-tools-app')
)
