import { i18nInstance } from '@signifyd/components'
import {
  DEVICE_PROFILING_THRESHOLD_70,
  DEVICE_PROFILING_THRESHOLD_80,
} from 'pages/DataQualityPage/components/DataComparisonTool/utils'
import { TotalResults } from 'pages/DataQualityPage/types'
import { isImproving } from 'pages/DataQualityPage/utils/dataComparison/utils'

export enum THRESHOLD_STATUSES {
  AT_RISK = 'AT_RISK',
  BELOW_RECOMMENDED = 'BELOW_RECOMMENDED',
  ALL_MET = 'ALL_MET',
}

const { t } = i18nInstance

export const calculatePercentage = (
  totalResults: number,
  filteredResults: number
): number => {
  const percentage = (filteredResults / totalResults) * 100
  const formattedPercentage = percentage.toFixed(2)

  return !Number.isNaN(formattedPercentage) ? Number(formattedPercentage) : 0
}

export const calculateThresholdStatus = (
  rangePercentageValue: number
): THRESHOLD_STATUSES => {
  if (
    rangePercentageValue >= DEVICE_PROFILING_THRESHOLD_70 &&
    rangePercentageValue < DEVICE_PROFILING_THRESHOLD_80
  ) {
    return THRESHOLD_STATUSES.BELOW_RECOMMENDED
  }

  if (rangePercentageValue >= DEVICE_PROFILING_THRESHOLD_80) {
    return THRESHOLD_STATUSES.ALL_MET
  }

  return THRESHOLD_STATUSES.AT_RISK
}

export const getMessage = (
  status: THRESHOLD_STATUSES,
  isImproving: boolean
): string => {
  switch (status) {
    case THRESHOLD_STATUSES.AT_RISK:
      return isImproving
        ? t('dataQualityPage.footerBanner.improvingAtRisk')
        : t('dataQualityPage.footerBanner.atRisk')
    case THRESHOLD_STATUSES.BELOW_RECOMMENDED:
      return isImproving
        ? t('dataQualityPage.footerBanner.belowRecommendedImproving')
        : t('dataQualityPage.footerBanner.belowRecommended')
    default:
      t('dataQualityPage.footerBanner.thresholdsMet')
  }

  return t('dataQualityPage.footerBanner.thresholdsMet')
}

export const calculateMessage = (
  field: string,
  totalResults: TotalResults,
  data: Record<string, Record<string, number>>
): string => {
  const rangeAPercentage = calculatePercentage(
    totalResults.rangeA,
    data.rangeA[field]
  )
  const rangeBPercentage = calculatePercentage(
    totalResults.rangeB,
    data.rangeB[field]
  )

  if (!Number.isNaN(rangeBPercentage)) {
    const status = calculateThresholdStatus(rangeBPercentage)

    return getMessage(status, isImproving(rangeBPercentage, rangeAPercentage))
  }

  const rangeAStatus = calculateThresholdStatus(rangeAPercentage)

  return getMessage(rangeAStatus, false)
}
