import { NavigateFunction } from 'react-router'

interface NavigateToTeamsProps {
  addedId?: number
  deletedId?: number
  navigate: NavigateFunction
  selectedTeams: Array<string>
}

export const navigateToTeams = ({
  addedId,
  deletedId,
  navigate,
  selectedTeams,
}: NavigateToTeamsProps): void => {
  const teamIds = selectedTeams.filter((team) => team !== String(deletedId))

  if (addedId) {
    teamIds.push(addedId.toString())
  }

  if (teamIds.length > 0) {
    navigate(`/teams?teamId=${teamIds.join('_')}`)
  } else {
    navigate('/teams')
  }
}
