import { FC } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form as AntForm } from '@ant-design/compatible'
import { Tooltip, Button, Row, Alert } from 'antd'
import cx from 'classnames'
import {
  WEBHOOK_EVENT,
  WEBHOOK_REQUEST_VERSIONS,
  AllWebhookData,
} from '@signifyd/http'
import { Text, Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { FormikContext, useFormik } from 'formik'
import * as yup from 'yup'
import Form, {
  FormikSubmitFunction,
  FormItem,
  Input,
  Select,
} from 'core/components/Form'
import styles from './WebhookForm.less'
import { WebhooksStatus } from '../../WebhooksContainer'

const { Item } = AntForm

interface FormValues {
  apiVersion: WEBHOOK_REQUEST_VERSIONS
  eventType?: WEBHOOK_EVENT | 'ORDER_CHECKPOINT_ACTION_UPDATE'
  url: string
  team: number
}

interface Props {
  teamId: number
  createWebhook: (payload: Partial<AllWebhookData>) => void
  webhookHTTPStatus: WebhooksStatus
}

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('Url is required')
    .matches(/^https:\/\//, 'Must start with https://'),
  eventType: yup.string().required('Event type is required'),
})

const WebhookForm: FC<Props> = ({
  teamId,
  createWebhook,
  webhookHTTPStatus,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'settingsPage.webhooksContainer.form',
  })

  const handleSubmit: FormikSubmitFunction<FormValues> = async (
    values
  ): Promise<void> => {
    const { eventType, url, apiVersion } = values

    createWebhook({
      teamId,
      apiVersion,
      topic: eventType,
      url,
    })
  }

  const formikInstance = useFormik<FormValues>({
    initialValues: {
      apiVersion: WEBHOOK_REQUEST_VERSIONS.V3,
      url: '',
      eventType: WEBHOOK_EVENT.CASE_CREATION,
      team: teamId,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  })

  const { apiVersion, url, eventType } = formikInstance.values

  const isSubmitting = webhookHTTPStatus.loading

  const disableSubmit =
    !url ||
    !eventType ||
    Object.keys(formikInstance.errors).length > 0 ||
    isSubmitting

  return (
    <FormikContext.Provider value={formikInstance}>
      <div className={styles.errors}>
        {apiVersion === WEBHOOK_REQUEST_VERSIONS.V2 && (
          <>
            <Alert message={t('webhookVersionWarning')} type="warning" />
            <Space size={17} />
          </>
        )}

        {webhookHTTPStatus.error && (
          <Alert message={webhookHTTPStatus.error} type="error" showIcon />
        )}
      </div>
      <Space size={10} />
      <Form onSubmit={formikInstance.handleSubmit} layout="vertical">
        <Row align="top" className={styles.row}>
          <FormItem
            className={cx(styles.label, styles.apiVersion)}
            name="apiVersion"
            label={
              <Text size="sm" className={styles.text}>
                {t('apiVersionLabel')}
              </Text>
            }
          >
            <Select
              name="apiVersion"
              data-test-id="apiVersionInput"
              data-analytics-id="api-version-select"
              options={Object.values(WEBHOOK_REQUEST_VERSIONS).map((key) => ({
                label: key,
                value: key,
                'data-analytics-id': `api-option-${key}`,
              }))}
            />
          </FormItem>

          <FormItem
            name="url"
            className={styles.label}
            label={
              <span>
                <Text size="sm" className={styles.text}>
                  {t('webhookAddressLabel')}
                </Text>
                <Tooltip
                  overlayClassName={styles.tooltipOverlay}
                  overlay={t('webhookAddressTooltip')}
                >
                  <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
              </span>
            }
          >
            <Input name="url" data-test-id="urlInput" placeholder="https://" />
          </FormItem>

          <FormItem
            className={styles.label}
            name="eventType"
            label={
              <span>
                <Text size="sm" className={styles.text}>
                  {t('eventTypeInputLabel')}
                </Text>
                <Tooltip
                  overlayClassName={styles.tooltipOverlay}
                  overlay={t('eventTypeInputTooltip')}
                >
                  <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
              </span>
            }
          >
            <Select
              data-test-id="webhookEventTypeSelect"
              name="eventType"
              value={
                apiVersion === WEBHOOK_REQUEST_VERSIONS.V3
                  ? 'ORDER_CHECKPOINT_ACTION_UPDATE'
                  : eventType
              }
              disabled={apiVersion === WEBHOOK_REQUEST_VERSIONS.V3}
              placeholder={t('eventTypePlaceholder')}
              options={Object.values(WEBHOOK_EVENT).map((eventType) => ({
                label: eventType,
                value: eventType,
                'data-analytics-id': `event-option-${eventType}}`,
              }))}
            />
          </FormItem>

          <Item label={<></>}>
            <Button
              type="primary"
              data-test-id="createWebhookButton"
              htmlType="submit"
              loading={isSubmitting}
              disabled={disableSubmit}
            >
              {t('addButton')}
            </Button>
          </Item>
        </Row>
      </Form>
    </FormikContext.Provider>
  )
}

export default WebhookForm
