export default {
  header: 'Welcome To Signifyd Developer Tools',
  headerDescription: 'Your integration starts here.',
  list: {
    store: 'Connect your online stores to Signifyd.',
    dataQuality:
      'Assess data quality of orders from your sandbox and production stores at any time.',
    integration: 'Manage your integrations in one place.',
  },
  getStartedButton: 'Get Started',
  errorHeader: 'Something went wrong.',
  errorBody: 'Please try again or contact support.',
}
