import { FC } from 'react'
import { colorSlate } from '@signifyd/colors'
import Ellipse from 'pages/DataQualityPage/components/Ellipse'
import styles from './DateRangeIndicator.less'

interface Props {
  label: string
  fromDate: string
  toDate: string
  color: string
}

const DateRangeIndicator: FC<Props> = ({ label, fromDate, toDate, color }) => {
  return (
    <>
      <div className={styles.dateRangeIndicator}>
        <Ellipse fill={color} stroke={colorSlate} />
        <div className={styles.indicatorContainer}>
          <div className={styles.indicatorLabel}>{label}</div>
          {fromDate} - {toDate}
        </div>
      </div>
    </>
  )
}

export default DateRangeIndicator
