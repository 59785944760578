import { FC } from 'react'
import { IconProps } from '@signifyd/components'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import HoverableIcon from 'core/components/HoverableIcon'

interface Props extends ButtonProps {
  Icon: FC<IconProps>
}

/**
 * Wraps antd v'3 weird IconButton pattern
 */
const IconButton: FC<Props> = ({ Icon, ...rest }) => {
  return <Button {...rest} ghost icon={<HoverableIcon Icon={Icon} />} />
}

export default IconButton
