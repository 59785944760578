import { FC } from 'react'
import { Space } from '@signifyd/components'
import styles from './SearchDisplay.less'

const SearchDisplay: FC = ({ children }) => {
  return (
    <>
      <div className={styles.container}>{children}</div>
      <Space size="lg" />
    </>
  )
}

export default SearchDisplay
