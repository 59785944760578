import { Space, ExternalLink, T4, P } from '@signifyd/components'
import styles from './ResourcesPageSection.less'

interface Description {
  preLinkText: string
  url: string
  linkText: string
  postLinkText: string
}

interface Props {
  title: string
  subTitle: string
  description: Description
}

const ResourcesPageSection: React.FC<Props> = ({
  title,
  subTitle,
  description,
}) => {
  const { preLinkText, url, linkText, postLinkText } = description

  return (
    <>
      <T4 className={styles.title}>{title}</T4>
      <Space size="md" />
      <P className={styles.subTitle}>{subTitle}</P>
      <Space size="sm" />
      <P>
        {preLinkText}{' '}
        <ExternalLink url={url} target="_blank">
          {linkText}
        </ExternalLink>{' '}
        {postLinkText}
      </P>
    </>
  )
}

export default ResourcesPageSection
