import { FC, ReactNode } from 'react'
import styles from './TeamDescriptionContent.less'

interface Props {
  text: string
  icon?: ReactNode
}

const TeamDescriptionContent: FC<Props> = ({ text, icon }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text} title={text}>
        {text}
      </div>
      {icon && <div className={styles.icon}>{icon}</div>}
    </div>
  )
}

export default TeamDescriptionContent
