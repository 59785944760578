import { FC, useEffect, useState } from 'react'
import { Alert, AlertProps, Button, Radio, RadioChangeEvent } from 'antd'
import { INTEGRATION_ANALYSIS_ORDER_CHANNEL } from '@signifyd/http'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import moment from 'moment-timezone'
import { colorGlitter, colorYonder } from '@signifyd/colors'
import DeviceProfilingSummary from 'pages/DataQualityPage/components/DeviceProfilingSummary'
import { useGetAggregateRangeDataPerRange } from 'pages/DataQualityPage/utils/flexibleSearch'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import {
  calculateThresholdMap,
  formatDate,
} from 'pages/DataQualityPage/components/DataComparisonTool/utils'
import { CalculationMethod } from 'pages/DataQualityPage/components/DeviceProfilingSummary/DeviceProfilingSummary'
import { DataComparisonView } from 'pages/DataQualityPage/types'
import SearchResultsTableByRange from 'pages/DataQualityPage/components/SearchResultsTableByRange/SearchResultsTableByRange'
import OrderChannel from 'pages/DataQualityPage/components/OrderChannel'
import DateRangeIndicator from 'pages/DataQualityPage/components/DateRangeIndicator'
import styles from './DataComparisonTool.less'

export interface DataComparisonToolProps {
  orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
  dataComparisonView: DataComparisonView
  setDataComparisonView: (data: DataComparisonView) => void
  calculationMethod: CalculationMethod
  setCalculationMethod: (data: CalculationMethod) => void
}

const DataComparisonTool: FC<DataComparisonToolProps> = ({
  orderChannel,
  calculationMethod,
  dataComparisonView,
  setDataComparisonView,
  setCalculationMethod,
}) => {
  const [mostRecentRange, setMostRecentRange] = useState<'rangeA' | 'rangeB'>(
    'rangeA'
  )

  const { t } = useTranslation()

  const {
    query: { rangeA, rangeB },
  } = useDataComparisonQueryParams()

  const fromDateA = rangeA.normalizedPurchaseCreatedAt?.min
    ? moment(rangeA.normalizedPurchaseCreatedAt.min)
    : undefined
  const toDateA = rangeA.normalizedPurchaseCreatedAt?.max
    ? moment(rangeA.normalizedPurchaseCreatedAt.max)
    : undefined

  const fromDateB = rangeB.normalizedPurchaseCreatedAt?.min
    ? moment(rangeB.normalizedPurchaseCreatedAt.min)
    : undefined
  const toDateB = rangeB.normalizedPurchaseCreatedAt?.max
    ? moment(rangeB.normalizedPurchaseCreatedAt.max)
    : undefined

  const { data: resultsA, isLoading: isResultsALoading } =
    useGetAggregateRangeDataPerRange({
      rangeType: 'rangeA',
      orderChannel,
    })

  const { data: resultsB, isLoading: isResultsBLoading } =
    useGetAggregateRangeDataPerRange({
      rangeType: 'rangeB',
      orderChannel,
    })

  useEffect(() => {
    if (rangeB?.normalizedPurchaseCreatedAt != null) {
      setMostRecentRange('rangeB')
    } else {
      setMostRecentRange('rangeA')
    }
  }, [rangeB.normalizedPurchaseCreatedAt])

  const handleModeChange = (e: RadioChangeEvent): void => {
    setCalculationMethod(e.target.value)
  }

  const handleDataComparisonViewChange = (): void => {
    if (dataComparisonView === 'orderChannel') {
      setDataComparisonView('table')
    } else {
      setDataComparisonView('orderChannel')
    }
  }

  const fieldsThresholdsMap = calculateThresholdMap(resultsA, resultsB)

  const rangeLabel = {
    rangeA: `dataQualityPage.thresholdAlerts.dateRangeA`,
    rangeB: `dataQualityPage.thresholdAlerts.dateRangeB`,
  }

  const totalResultsRangeA = resultsA?.filters.investigationId?.count ?? 0
  const totalResultsRangeB = resultsB?.filters.investigationId?.count ?? 0

  if (!!rangeA.normalizedPurchaseCreatedAt && isResultsALoading) {
    return null
  }

  if (!!rangeB.normalizedPurchaseCreatedAt && isResultsBLoading) {
    return null
  }

  return (
    <>
      <div className={styles.descriptionContainer}>
        <div data-test-id="alerts" className={styles.descriptionAlerts}>
          {Object.keys(fieldsThresholdsMap).map((entry) => {
            const message: Record<string, string> = {
              warning: t('dataQualityPage.thresholdAlerts.below80'),
              error: t('dataQualityPage.thresholdAlerts.below70'),
            }

            return (
              <>
                {fieldsThresholdsMap[entry].status !== 'success' && (
                  <Alert
                    message={
                      <div className={styles.thresholdAlert}>
                        <Trans
                          components={{
                            bold: <Text weight="semibold" />,
                          }}
                        >
                          {rangeLabel[mostRecentRange]}
                        </Trans>
                        <Text>
                          {t(`dataQualityPage.bannerLabels.${entry}`)}
                        </Text>
                        <span>
                          {message[fieldsThresholdsMap[entry].status]}
                        </span>
                      </div>
                    }
                    type={
                      fieldsThresholdsMap[entry].status as AlertProps['type']
                    }
                    showIcon
                  />
                )}
              </>
            )
          })}
        </div>

        <div
          data-test-id="dataRangeIndicators"
          className={styles.dataRangeIndicators}
        >
          {!!fromDateA && !!toDateA && (
            <DateRangeIndicator
              label={t('dataQualityPage.dateRangeA')}
              fromDate={formatDate(fromDateA)}
              toDate={formatDate(toDateA)}
              color={colorGlitter}
            />
          )}
          {!!fromDateB && !!toDateB && (
            <DateRangeIndicator
              label={t('dataQualityPage.dateRangeB')}
              fromDate={formatDate(fromDateB)}
              toDate={formatDate(toDateB)}
              color={colorYonder}
            />
          )}
        </div>
      </div>
      <div className={styles.subHeaderNavigationContainer}>
        <div className={styles.tabButtons}>
          <Radio.Group onChange={handleModeChange} value={calculationMethod}>
            <Radio.Button value="percentage">
              {t('dataQualityPage.calculationMethod.percentage')}
            </Radio.Button>
            <Radio.Button value="count">
              {t('dataQualityPage.calculationMethod.count')}
            </Radio.Button>
          </Radio.Group>
        </div>
        <Button
          className={styles.dataComparisonBtn}
          type="primary"
          onClick={() => {
            handleDataComparisonViewChange()
          }}
        >
          {dataComparisonView === 'table'
            ? t('dataQualityPage.dataComparisonViewBtn.viewByChannel')
            : t('dataQualityPage.dataComparisonViewBtn.goBack')}
        </Button>
      </div>
      {dataComparisonView === 'table' && (
        <>
          <DeviceProfilingSummary
            orderChannel={orderChannel}
            calculationMethod={calculationMethod}
          />
          <SearchResultsTableByRange
            totalResultsPerRange={{
              rangeA: totalResultsRangeA,
              rangeB: totalResultsRangeB,
            }}
            orderChannel={orderChannel}
          />
        </>
      )}
      {dataComparisonView === 'orderChannel' && (
        <OrderChannel calculationMethod={calculationMethod} />
      )}
    </>
  )
}

export default DataComparisonTool
