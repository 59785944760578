import { FC, ReactNode } from 'react'
import { CustomerNavBar, Layout } from '@signifyd/components'
import { useStoreState } from 'stores'
import ContentWrapper from './ContentWrapper'

interface Props {
  backgroundColor?: string
  Header?: ReactNode
}

const PageWrapper: FC<Props> = ({
  Header,
  children,
  backgroundColor = 'white',
}) => {
  const { currentUser: user, userConfigs } = useStoreState(
    (state) => state.user
  )

  if (!user || !userConfigs) {
    return null
  }

  return (
    <>
      <Layout
        innerMinWidth={null}
        innerMaxWidth={null}
        innerStyle={{ padding: 0 }}
      >
        <Layout.NavBar>
          <CustomerNavBar user={user} userConfigs={userConfigs} />
        </Layout.NavBar>
        {Header && Header}
        <ContentWrapper backgroundColor={backgroundColor}>
          {children}
        </ContentWrapper>
        6
      </Layout>
    </>
  )
}

export default PageWrapper
