import { createEnumParam, FILTER_JOIN_TYPE } from '@signifyd/utils'
import { useQueryParam, UrlUpdateType } from 'use-query-params'

interface UseGlobalFilterJoinType {
  joinType?: FILTER_JOIN_TYPE | null
  setJoinType: (newValue: FILTER_JOIN_TYPE, updateType?: UrlUpdateType) => void
}

export const useGlobalFilterJoinType = (): UseGlobalFilterJoinType => {
  const [joinType, setJoinType] = useQueryParam(
    'joinOn',
    createEnumParam(FILTER_JOIN_TYPE, FILTER_JOIN_TYPE.AND)
  )

  return { joinType, setJoinType }
}
