import { FC } from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { SEARCH_ORDER } from '@signifyd/http'
import cx from 'classnames'
import styles from './OrderIcon.less'

export interface Props {
  currentOrder?: SEARCH_ORDER | null
  onClick: (value: SEARCH_ORDER) => void
}

const OrderIcon: FC<Props> = ({ currentOrder, onClick }) => {
  return (
    <div className={styles.orderIcon}>
      <CaretUpOutlined
        className={cx(
          styles.orderIconUp,
          currentOrder === SEARCH_ORDER.ASCENDING && styles.orderIconSelected
        )}
        onClick={() => {
          onClick(SEARCH_ORDER.ASCENDING)
        }}
        data-test-id="upSortIcon"
      />
      <CaretDownOutlined
        className={cx(
          styles.orderIconDown,
          currentOrder === SEARCH_ORDER.DESCENDING && styles.orderIconSelected
        )}
        onClick={() => {
          onClick(SEARCH_ORDER.DESCENDING)
        }}
        data-test-id="downSortIcon"
      />
    </div>
  )
}

export default OrderIcon
