import { CopyOutlined, CheckOutlined } from '@ant-design/icons'
import { Flex, Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { FC, ReactElement, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styles from './CopyDestinationButton.less'

interface Props {
  text: string
}

export const CopyDestinationButton: FC<Props> = ({ text }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'settingsPage.webhooksContainer.form',
  })
  const [isClicked, setIsClicked] = useState(false)

  const getIcon = (isClicked: boolean): ReactElement => {
    if (isClicked) {
      return (
        <Tooltip title={t('copiedTooltip')}>
          <CheckOutlined
            className={styles.checkedButton}
            data-test-id="checkedButton"
          />
        </Tooltip>
      )
    }

    return (
      <Tooltip title={t('copyDestinationTooltip')}>
        <CopyOutlined />
      </Tooltip>
    )
  }

  return (
    <Flex align="center" gap={5} justify="space-between">
      <Text className={styles.address}>{text}</Text>
      <CopyToClipboard text={text}>
        <Button
          data-test-id={`copyButton-${text}`}
          type="link"
          className={styles.copyButton}
          icon={getIcon(isClicked)}
          onClick={() => setIsClicked(true)}
        />
      </CopyToClipboard>
    </Flex>
  )
}
