import { FC } from 'react'
import { Form } from '@ant-design/compatible'
import { FormItemProps } from '@ant-design/compatible/lib/form'
import { joinClassNames } from '@signifyd/utils'
import { useField } from 'formik'
import styles from './Form.less'

const { Item } = Form

const FormItem: FC<FormItemProps & { name: string }> = ({
  label,
  name,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { touched, error }] = useField({ name })

  return (
    <Item
      label={label}
      className={joinClassNames([styles.formItem, props.className])}
      validateStatus={touched && error ? 'error' : undefined}
      help={touched && error}
      {...props}
    />
  )
}

export default FormItem
