import { FC, useEffect, useMemo } from 'react'
import { Divider } from 'antd'
import { INTEGRATION_GATEWAY_ID } from '@signifyd/http'
import { Text, P, Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'stores'
import AuthNetIntegrationContainer from './AuthNetIntegrationContainer'
import styles from './IntegrationsContainer.less'

interface Props {
  teamId: number
}

const IntegrationsContainer: FC<Props> = ({ teamId }) => {
  const { t } = useTranslation()

  const { getGateways } = useStoreActions((actions) => actions.integrations)
  const gateways = useStoreState((state) => state.integrations.gateways)

  useEffect(() => {
    if (!gateways) {
      getGateways(teamId)
    }
  }, [teamId, getGateways, gateways])

  const authNetIntegration = useMemo(() => {
    return gateways?.find(
      ({ typeId }) => typeId === INTEGRATION_GATEWAY_ID.AUTHORIZE_NET
    )
  }, [gateways])

  return (
    <section className={styles.container} data-test-id="integrations-container">
      <Text size="lg">{t('settingsPage.integrationsContainer.title')}</Text>
      <Space size="sm" />
      <P>{t('settingsPage.integrationsContainer.description')}</P>

      <Divider className={styles.divider} />

      <AuthNetIntegrationContainer
        teamId={teamId}
        authNetIntegration={authNetIntegration}
      />
    </section>
  )
}

export default IntegrationsContainer
