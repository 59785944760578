import columnFilters from './columnFilters'
import createTeamPage from './createTeamPage'
import dataQualityPage from './dataQualityPage'
import jsonViewerPage from './jsonViewerPage'
import resourcesPage from './resourcesPage'
import settingsPage from './settingsPage'
import teamForm from './teamForm'
import teamsPage from './teamsPage'
import welcomePage from './welcomePage'

export default {
  columnFilters,
  createTeamPage,
  dataQualityPage,
  jsonViewerPage,
  resourcesPage,
  settingsPage,
  teamForm,
  teamsPage,
  welcomePage,
}
