import { SUBMISSION_POLICY } from '@signifyd/http'

export const TEAMS_AUTO_SUBMIT_FOR_GUARANTEE_MAPPING = {
  [SUBMISSION_POLICY.COMPLETE]: {
    value: 'All Orders',
    tooltip: 'Every order is automatically submitted for guarantee.',
  },
  [SUBMISSION_POLICY.EMPTY]: {
    value: 'Manual Submit',
    tooltip: (
      <span>
        Orders must be submitted for guarantee manually.{' '}
        <a href="https://www.signifyd.com/resources/faq/signifyd-console/how-submit/">
          Learn how
        </a>
        .
      </span>
    ),
  },
  [SUBMISSION_POLICY.NONE]: {
    value: 'Manual Submit',
    tooltip: (
      <span>
        Orders must be submitted for guarantee manually.{' '}
        <a href="https://www.signifyd.com/resources/faq/signifyd-console/how-submit/">
          Learn how
        </a>
        .
      </span>
    ),
  },
  [SUBMISSION_POLICY.COMPLETE_EX_GUARANTEED_PAYMENT_GATEWAYS]: {
    value: 'COMPLETE_EX_GUARANTEED_PAYMENT_GATEWAYS',
    tooltip:
      'All orders except for Paypal, Affirm, Amazon are submitted for guarantee.',
  },
  [SUBMISSION_POLICY.NON_US_ANYTHING]: {
    value: 'ONLY NON US ORDERS',
    tooltip:
      'Only orders with billing, IP, or delivery countries outside of the US are automatically submitted for guarantee.',
  },
  [SUBMISSION_POLICY.PAYMENT_GATEWAY_SHOPIFY_PAYMENTS]: {
    value: 'ONLY SHOPIFY PAYMENTS',
    tooltip:
      'Only Shopify Payment orders are submitted for guarantee automatically.',
  },
  [SUBMISSION_POLICY.CUSTOMER_SUBMIT_FOR_GUARANTEE_INDICATOR]: {
    value: 'NONE',
    tooltip: (
      <span>
        Orders must be submitted for guarantee manually.{' '}
        <a href="https://www.signifyd.com/resources/faq/signifyd-console/how-submit/">
          Learn how
        </a>
        .
      </span>
    ),
  },
  [SUBMISSION_POLICY.COMPLETE_EX_GUARANTEED_PG_NON_CC_PM]: {
    value: 'ONLY CREDIT CARD ORDERS',
    tooltip:
      'All orders except for paypal, affirm, amazon, klarna, bitcoin, cash, check, loan, ach, wire transfer, purchase order are submitted for guarantee.',
  },
  [SUBMISSION_POLICY.CUSTOM]: {
    value: 'Custom',
    tooltip:
      'Your team has a custom auto-submit for guarantee policy. Contact our customer support team for additional details.',
  },
}
