import { checkpointAction, orderChannel } from './enums'

export const graphFields: Record<string, string> = {
  deviceFingerprintingRequested: 'Device profiling call made by Signifyd',
  deviceId: 'Device data present',
  orderSessionId: 'Order session ID',
}

export default {
  pageTitle: 'Data Quality',
  searchPlaceholder: 'Search by case or order ID',
  totalRows_one: '{{count}} Result Found',
  totalRows_other: '{{count}} Results Found',
  refreshButton: 'Refresh Results',
  placeHolders: {
    noTeams: {
      title: 'You don’t have teams yet!',
      description:
        'Create a test team or reach out to your implementations manager. Once you send orders, we’ll display detailed results here.',
    },
    noTeamSelected: {
      title: 'Select a team to review order traffic.',
    },
    noCases: {
      resetFiltersButton: 'Reset Filters',
      title: {
        noFilters: 'Signifyd hasn’t received cases for this team!',
        hasFilters:
          'No cases could be found that meet the search or filter criteria',
      },
      topDescription:
        'Once you send orders, we’ll recognize case traffic and display detailed results here.',
      bottomDescription:
        'If you sent orders already, refresh results to continue.',
    },
    noResults: 'No data',
  },
  dateRange: {
    presetRangeToday: 'Today',
    presetRangeYesterday: 'Yesterday',
    presetRangeSevenDays: 'Last 7 Days',
    presetRangeFourteenDays: 'Last 14 Days',
    presetRange30Days: 'Last 30 Days',
  },
  guaranteeDecisionFilter: {
    placeholder: 'Filter By Guarantee Decision',
  },
  missingFields: {
    warning:
      "<bold>Oops — you're missing {{count}} expected fields that are necessary for the integration.</bold> Visit the API event page for further details, steps to solve and if there are empty fields.",
    eventApi: '{{api}}',
    viewEvent: 'View API event & JSON',
    viewDocs: 'View {{api}} docs',
    expected: 'EXPECTED MISSING FIELDS',
    apiCalls:
      'Most recent {{api}} call: {{- time}} | Total {{api}} calls: {{calls}}',
  },
  teamsFilter: {
    title: 'Team:',
    select: {
      placeholder: 'Select Team',
      testTeamOptionLabel: '(Test) {{teamName}}',
    },
  },
  yesNoValues: {
    true: 'Yes',
    false: 'No',
  },
  table: {
    amountColumn: {
      title: 'Amount',
    },
    caseIdColumn: {
      title: 'Case ID',
    },
    checkpointActionColumn: {
      title: 'Checkpoint Action',
      values: checkpointAction,
    },
    deviceFingerprintingColumn: {
      title: 'Device Fingerprinting',
      values: {
        received: 'Received',
        notReceived: 'Not received',
        linkText: 'View steps to solve',
      },
      popoverTitle: 'Troubleshoot device data',
      popoverSubtitle:
        'We haven’t received device data. This integration is not setup or is not working correctly.',
    },
    emailColumn: {
      title: 'Email',
    },
    fieldQualityColumn: {
      title: 'Field Quality',
      values: {
        missingFieldsTag_one: '{{count}} expected field missing',
        missingFieldsTag_other: '{{count}} expected fields missing',
        allFields: 'All expected fields present',
      },
      tooltip: {
        open: 'Click to collapse',
        closed: 'Click to open',
      },
    },
    jsonColumn: {
      title: 'API Events & JSON',
      tooltip: 'View JSON in new tab',
    },
    orderChannelColumn: {
      title: 'Order Channel',
      values: orderChannel,
    },
    orderDateColumn: {
      title: 'Order Date',
    },
    orderIdColumn: {
      title: 'Order ID',
      tooltip: 'Open Case {{caseId}}',
    },
    paymentMethodColumn: {
      title: 'Payment Method',
    },
    deviceFingerprintingRequestedColumn: {
      title: 'Device Profiling Call Made By Signifyd',
    },
    deviceIdColumn: {
      title: 'Device Data Present',
    },
    orderSessionIdColumn: {
      title: 'Order Session ID',
    },
  },
  api: {
    teams: {
      warning:
        'Invalid URL provided. Please choose a Team on this page to view Data Quality.',
    },
  },

  tabs: {
    standardSearch: 'Standard search',
    dataComparisonTool: 'Data comparison tool',
  },
  dateRangeA: 'Date range A',
  dateRangeB: 'Date range B',
  orderChannel: 'Order channel',
  search: 'Search',
  dateRangeSearch: 'Date range',
  clear: 'Clear',
  compareData: 'Compare data',
  bannerLabels: {
    ...graphFields,
  },
  thresholdAlerts: {
    dateRangeA: '<bold>Date range A:</bold>',
    dateRangeB: '<bold>Date range B:</bold>',
    below80: 'is below the recommended threshold of 80%.',
    below70: 'is well below the threshold of 80%.',
  },
  footerBanner: {
    thresholdsMet: 'Thresholds met',
    belowRecommended: 'Thresholds below recommended',
    belowRecommendedImproving: 'Improving but below recommended',
    improvingAtRisk: 'Improving but still at risk',
    atRisk: 'At risk',
  },
  calculationMethod: {
    percentage: 'Percentage',
    count: 'Count',
  },
  dataComparisonViewBtn: {
    viewByChannel: 'View by order channel',
    goBack: 'Go back',
  },
  graphFooterTooltip:
    'Threshold messaging only applies to the {{currentRange}}.',
  readOnly:
    'Data on below tables is read-only and filtering is not available. ',
  orderChannels: {
    all: 'All',
    ...orderChannel,
  },
  rangeAMustBeOlderThanRangeB:
    'The dates in date range A must be older than date range B. ',
  breadcrumbs: {
    dataQualityTable: 'Data quality table',
    orderChannel: 'Order channel',
  },
}
