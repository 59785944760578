import { FC } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Divider } from 'antd'
import { P, T2, Space } from '@signifyd/components'
import { colorTurquoise } from '@signifyd/colors'
import { useTranslation, Trans } from 'react-i18next'
import styles from './TeamCreationSuccessModal.less'

interface Props {
  dismissModal: () => void
  viewResources: () => void
}

const TeamCreationSuccessModal: FC<Props> = ({
  dismissModal,
  viewResources,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open
      title={
        <div>
          <CheckCircleOutlined
            style={{ fontSize: 72, color: colorTurquoise }}
          />
          <Space size="md" />
          <T2>{t('teamsPage.teamCreationSuccessModal.title')}</T2>
        </div>
      }
      footer={null}
      className={styles.modal}
    >
      <P>{t('teamsPage.teamCreationSuccessModal.description1')}</P>
      <Divider />
      <P>
        <Trans i18nKey="teamsPage.teamCreationSuccessModal.description2" />
      </P>
      <Space size="sm" />
      <Button
        type="primary"
        onClick={() => {
          dismissModal()
          viewResources()
        }}
      >
        {t('teamsPage.teamCreationSuccessModal.viewIntegrationButton')}
      </Button>
      <Space size="xs" />
      <Button type="link" onClick={dismissModal}>
        {t('teamsPage.teamCreationSuccessModal.viewTeamDetailsButton')}
      </Button>
    </Modal>
  )
}

export default TeamCreationSuccessModal
