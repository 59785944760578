import { useTranslation } from 'react-i18next'
import { MonitorIcon } from '@signifyd/components'
import { UserTeam } from '@signifyd/http'
import PagePlaceholder from 'core/components/PagePlaceholder'
import { useTableQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch'

interface Props {
  teams?: Array<UserTeam>
  children: JSX.Element
}

const TabbedDisplay: React.FC<Props> = ({ teams, children }) => {
  const { query: filters } = useTableQueryParams()

  const { t } = useTranslation()

  const selectedTeam = filters.teamId?.[0]
  const showNoSelectedTeamPlaceholder = teams?.length && !selectedTeam

  if (!teams?.length) {
    return (
      <PagePlaceholder
        data-test-id="noResultsNoTeams"
        icon={MonitorIcon}
        description={t('dataQualityPage.placeHolders.noTeams.description')}
        title={t('dataQualityPage.placeHolders.noTeams.title')}
      />
    )
  }

  if (showNoSelectedTeamPlaceholder) {
    return (
      <PagePlaceholder
        data-test-id="noResultsNoSelectedTeam"
        icon={MonitorIcon}
        title={t('dataQualityPage.placeHolders.noTeamSelected.title')}
      />
    )
  }

  return <>{children}</>
}

export default TabbedDisplay
