export default {
  productName: 'Developer Tools',
  pageTitle: 'Create Test Team',
  cancelButton: 'Cancel',
  submitButton: 'Create Test Team',
  errorMessage: 'Error trying to update Team {{teamName}}',
  cancelNotification: {
    message: "You haven't completed a task",
    description:
      'Navigating away from this page will discard any progress. Close to remain on page or leave page.',
    button: 'Leave Page',
  },
}
