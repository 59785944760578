import { mapTeamsToEntities, SelectModalEntity } from '@signifyd/components'
import {
  BasicTeam,
  searchTeams,
  searchUserTeams,
  User,
  UserTeam,
} from '@signifyd/http'

const filterAndMapTeams = (
  teams: Array<UserTeam | BasicTeam>
): Array<SelectModalEntity> =>
  mapTeamsToEntities(
    teams.filter((team) => {
      if ('active' in team) {
        return team.active
      }

      return true
    })
  )

export const selectFetchFunction =
  ({ isAdmin, userId }: User) =>
  async (teamIds?: Array<string>) => {
    const {
      data: { teams },
    } = isAdmin
      ? await searchTeams({ teamIds })
      : await searchUserTeams(userId, {
          teamIds,
        })

    return filterAndMapTeams(teams)
  }

export const selectSearchFunction =
  ({ isAdmin, userId }: User) =>
  async (searchTerm?: string) => {
    const isPotentialId = !Number.isNaN(Number(searchTerm))

    if (isPotentialId) {
      const {
        data: { teams },
      } = isAdmin
        ? await searchTeams({ teamIds: searchTerm })
        : await searchUserTeams(userId, {
            teamIds: searchTerm,
          })

      // We want this to fallthrough and do a search by name if no ids are found
      if (teams.length) {
        return filterAndMapTeams(teams)
      }
    }

    const {
      data: { teams },
    } = isAdmin
      ? await searchTeams({
          teamName: searchTerm,
        })
      : await searchUserTeams(userId, {
          teamName: searchTerm,
        })

    return filterAndMapTeams(teams)
  }
