import { FC, useEffect } from 'react'
import {
  AppAnalytics,
  useAppCues,
  ErrorBoundary,
  i18nInstance,
  initLocalization,
  ShadowUserBanner,
  defaultQueryClientConfig,
  AppConfigProvider,
  QAInfoPopover,
  ProdWarningModal,
} from '@signifyd/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { I18nextProvider } from 'react-i18next'
import moment from 'moment-timezone'
import { devToolsTokens } from '@signifyd/sig-keys'
import { legacyTheme } from '@signifyd/ant'
import { useStoreState } from 'stores'
import locales from 'locales'
import AppRoutes from './AppRoutes'

initLocalization(locales)

const App: FC = () => {
  const { currentUser, userConfigs } = useStoreState((state) => state.user)
  const userTimeZone = currentUser?.uiState?.timeZone?.name

  const user = currentUser!

  useAppCues({
    user,
    appName: 'customer-dev-tools',
    allowedBuildEnvs: ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  const queryClient = new QueryClient(defaultQueryClientConfig)

  return (
    <div className="secondGen">
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AppConfigProvider
            theme={legacyTheme}
            user={user}
            userConfigs={userConfigs!}
          >
            {/** This app is not currently translated, but we still built in localization for when that happens */}
            <I18nextProvider i18n={i18nInstance}>
              <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                  <>
                    <AppAnalytics
                      user={user}
                      logRocketId={devToolsTokens.logRocketId}
                      heapAppId={devToolsTokens.heapAppId}
                      enabled={process.env.BUILD_ENV === 'production'}
                    />
                    <AppRoutes user={user} />
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ShadowUserBanner currentUser={user} />
                    <ProdWarningModal />
                    <QAInfoPopover />
                  </>
                </ErrorBoundary>
              </QueryClientProvider>
            </I18nextProvider>
          </AppConfigProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
