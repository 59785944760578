import {
  useQueryParams,
  NumberParam,
  DecodedValueMap,
  SetQuery,
  withDefault,
} from 'use-query-params'
import { DEFAULT_PAGINATION_LIMIT } from 'core/constants'

const DEFAULT_PAGE = 1

const paginationQueryMap = {
  current: withDefault(NumberParam, DEFAULT_PAGE),
  pageSize: withDefault(NumberParam, DEFAULT_PAGINATION_LIMIT),
  total: NumberParam,
}

export type PaginationQueryResult = DecodedValueMap<typeof paginationQueryMap>

export interface UseTablePaginationHook {
  query: DecodedValueMap<typeof paginationQueryMap>
  setQuery: SetQuery<typeof paginationQueryMap>
  resetPagination: () => void
}

export const useTablePagination = (): UseTablePaginationHook => {
  const [query, setQuery] = useQueryParams(paginationQueryMap)

  // Do not change total when resetting pagination, or it will cause jankiness in the UI
  const resetPagination = (): void => {
    setQuery({
      current: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGINATION_LIMIT,
    })
  }

  return { query, setQuery, resetPagination }
}
