export default {
  sectionOneTitle: 'Tell us about your online store',
  sectionTwoTitle: 'Additional store details',
  labels: {
    storeName: 'Store Name',
    storeUrl: 'Store URL e.g. https://mystore.com',
    programmingLanguage: 'Programming language',
    selectedPaymentGateway: 'Payment gateway',
    mobileAppPlatform: 'Native mobile app',
  },
  orderAlert: {
    message: 'Orders will be marked as “Test”',
    description:
      'Any order submitted via this test team will not include Guaranteed Fraud Protection and thus you will not be charged.',
  },
  phpAlert:
    'Our <externalLink>PHP client library</externalLink> contains instructions and examples to help you get started.',
  authorizeDotNetAlert:
    'Our <externalLink>payment gateway integration with Authorize.net</externalLink> only requires credentials & keys to enable. Do this at any time to include AVS and CVV codes in your test.',
  mobilePlatformAlert:
    'Our <externalLink>SDKs</externalLink> provide mobile application developers to help you integrate faster.',
}
