import {
  getIntegrationAnalysis,
  IntegrationAnalysisOrder,
  IntegrationAnalysisSearchRequest,
  SEARCH_BOOL_OPERATOR,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getEvents, SelectedEvent } from 'core/utils/integrationEvents/utils'

export type UseIntegrationAnalysisResult = UseQueryResult<{
  selectedCase: IntegrationAnalysisOrder
  events: Array<SelectedEvent>
}>

export const useGetIntegrationAnalysis = (
  investigationId?: string
): UseIntegrationAnalysisResult => {
  const request: IntegrationAnalysisSearchRequest = {
    query: {
      [SEARCH_BOOL_OPERATOR.AND]: [
        {
          field: {
            fieldName: SEARCH_FIELD.investigationId,
            operator: SEARCH_OPERATOR.EQUAL,
            values: investigationId!,
          },
        },
      ],
    },
  }

  const useQueryResponse = useQuery({
    queryKey: ['integrationAnalysis', investigationId],
    queryFn: async () => {
      const { data } = await getIntegrationAnalysis(request)

      const [selectedCase] = data.results ?? []

      const events = getEvents(selectedCase)

      return { selectedCase, events }
    },
    enabled: !!investigationId,
  })

  return useQueryResponse
}

export default useGetIntegrationAnalysis
