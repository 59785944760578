import { StoreDataUpdate, replaceStore } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

const { t } = i18nInstance

interface UpdateUserTeamStoreProps {
  storeData: StoreDataUpdate
  teamName: string
}

const useUpdateUserTeamStore = (): UseMutationResult<
  StoreDataUpdate,
  unknown,
  UpdateUserTeamStoreProps
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ storeData }: UpdateUserTeamStoreProps) => {
      const { data } = await replaceStore(storeData)

      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['enhancedUserTeams'])
    },
    onError: (_, { teamName }) => {
      message.error(
        t('settingsPage.editTeamContainer.errorMessage', { teamName })
      )
    },
  })
}

export default useUpdateUserTeamStore
