import { FC } from 'react'
import { Layout } from '@signifyd/components'
import styles from './PageWrapper.less'

interface Props {
  backgroundColor?: string
}

const ContentWrapper: FC<Props> = ({ children, backgroundColor = 'white' }) => {
  return (
    <Layout.Content
      className={styles.content}
      innerClassName={styles.wrapper}
      innerMinWidth={null}
    >
      <section style={{ backgroundColor }} className={styles.container}>
        <div className={styles.contentWrapper}>{children}</div>
      </section>
    </Layout.Content>
  )
}

export default ContentWrapper
