import { ExternalLink } from '@signifyd/components'
import { ReactElement } from 'react'

interface TranslationLinks {
  [key: string]: ReactElement
}
export const getTranslatedLinks = (links: {
  [key: string]: string
}): TranslationLinks => {
  const mappedLinks = Object.entries(links).map(([key, link]) => {
    return [
      key,
      <ExternalLink key={link} url={link} target="_blank">
        Placeholder
      </ExternalLink>,
    ]
  })

  return Object.fromEntries(mappedLinks)
}
