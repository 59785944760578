import { FC } from 'react'
import { ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import { colorRed, colorBondi } from '@signifyd/colors'
import { useTranslation } from 'react-i18next'
import { IntegrationsHttpStatus } from 'stores/integrations'
import styles from './AuthNetFormFeedback.less'

interface Props {
  hasAuthNet: boolean
  gatewaySubmitStatus: IntegrationsHttpStatus
}

const AuthNetFormFeedback: FC<Props> = ({
  gatewaySubmitStatus,
  hasAuthNet,
}) => {
  const { t } = useTranslation()

  return (
    <section className={styles.authNetFeeback}>
      {hasAuthNet && gatewaySubmitStatus.success && (
        <Row justify="start">
          <Col span={20}>
            <ExclamationCircleTwoTone twoToneColor={colorRed} />
            <span className={styles.success}>
              {t(
                'settingsPage.integrationsContainer.authDotNet.feedback.success'
              )}
            </span>
          </Col>
        </Row>
      )}
      {gatewaySubmitStatus.error && (
        <Row justify="start">
          <Col span={20}>
            <ExclamationCircleTwoTone twoToneColor={colorRed} />
            <span className={styles.error}>{gatewaySubmitStatus.error}</span>
          </Col>
        </Row>
      )}
      {gatewaySubmitStatus.loading && (
        <div>
          <LoadingOutlined style={{ color: colorBondi }} />
          <span className={styles.messageText}>
            {t(
              'settingsPage.integrationsContainer.authDotNet.feedback.loading'
            )}
          </span>
        </div>
      )}
    </section>
  )
}

export default AuthNetFormFeedback
