import { FC, createContext, useContext, useState } from 'react'

export interface DataQualityContext {
  isFilterEnabled: boolean
  setIsFilterEnabled: (enable: boolean) => void
}

export const dataQualityContext = createContext({} as DataQualityContext)

export const useDataQualityContext = (): DataQualityContext => {
  return useContext(dataQualityContext)
}

export const DataQualityProvider: FC = ({ children }) => {
  const [isFilterEnabled, setIsFilterEnabled] = useState<boolean>(true)

  return (
    <dataQualityContext.Provider
      value={{
        isFilterEnabled,
        setIsFilterEnabled,
      }}
    >
      {children}
    </dataQualityContext.Provider>
  )
}
