import { FC, ReactNode } from 'react'
import { Text } from '@signifyd/components'
import styles from './WelcomeListItem.less'

interface Props {
  icon: ReactNode
  text: string
}

const WelcomeListItem: FC<Props> = ({ icon, text }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.icon}>{icon}</div>
        <Text className={styles.text}>{text}</Text>
      </div>
    </>
  )
}

export default WelcomeListItem
