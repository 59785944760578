import { FC } from 'react'
import styles from './TableTag.less'

export interface BadgeProps {
  color: string
  position: 'start' | 'end'
  count: number
}

const Badge: FC<BadgeProps> = ({ color, count }) => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={styles.badge}
      data-test-id="tableTagBadge"
    >
      {count > 99 ? '99+' : count}
    </div>
  )
}

export default Badge
