import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select, Tooltip } from 'antd'
import { RangePickerValue, DatePicker, Text } from '@signifyd/components'
import moment from 'moment-timezone'
import {
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  RangeSearchValue,
} from '@signifyd/http'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import { useDataComparisonQueryParams } from 'pages/DataQualityPage/utils/flexibleSearch/useTableComparisonQueryParams'
import { getCurrentDate, getDateNDaysAgo } from 'core/utils/date/dateRanges'
import styles from './DataComparisonFilters.less'

const { RangePicker } = DatePicker

type OrderChannelSelect = INTEGRATION_ANALYSIS_ORDER_CHANNEL | 'All'

interface Props {
  handleCompare: () => void
  handleReset: () => void
  handleOrderChannel: (
    orderChannel?: INTEGRATION_ANALYSIS_ORDER_CHANNEL
  ) => void
}

const DataComparisonFilters: FC<Props> = ({
  handleCompare,
  handleReset,
  handleOrderChannel,
}) => {
  const { query, setQuery, resetTableFilters } = useDataComparisonQueryParams()

  const { rangeA, rangeB } = query

  const { t } = useTranslation()

  const handleRangeChange = (
    value: RangePickerValue,
    rangeType: 'rangeA' | 'rangeB'
  ): void => {
    if (!value) {
      setQuery({
        [rangeType]: { normalizedPurchaseCreatedAt: null },
      })

      return
    }

    const [startDate, endDate] = value

    if (!startDate || !endDate) {
      setQuery({
        [rangeType]: { normalizedPurchaseCreatedAt: null },
      })

      return
    }

    const normalizedPurchaseCreatedAt = {
      min: moment(startDate).startOf('day').toISOString() as RangeSearchValue,
      max: moment(endDate).endOf('day').toISOString() as RangeSearchValue,
    }

    setQuery({ [rangeType]: { ...[rangeType], normalizedPurchaseCreatedAt } })
  }
  const fromDateA = rangeA.normalizedPurchaseCreatedAt?.min
    ? moment(rangeA.normalizedPurchaseCreatedAt.min)
    : undefined
  const toDateA = rangeA.normalizedPurchaseCreatedAt?.max
    ? moment(rangeA.normalizedPurchaseCreatedAt.max)
    : undefined

  const fromDateB = rangeB.normalizedPurchaseCreatedAt?.min
    ? moment(rangeB.normalizedPurchaseCreatedAt.min)
    : undefined
  const toDateB = rangeB.normalizedPurchaseCreatedAt?.max
    ? moment(rangeB.normalizedPurchaseCreatedAt.max)
    : undefined

  const handleOrderChannelChange = (value: OrderChannelSelect): void => {
    handleOrderChannel(undefined)

    if (value !== 'All') {
      handleOrderChannel(value)
    }
  }

  const currentDate = getCurrentDate()

  const ranges: Record<string, RangeValueType<moment.Moment>> = {
    [t('dataQualityPage.dateRange.presetRangeToday')]: [
      currentDate,
      currentDate,
    ],
    [t('dataQualityPage.dateRange.presetRangeYesterday')]: [
      getDateNDaysAgo(1),
      getDateNDaysAgo(1).endOf('day'),
    ],
    [t('dataQualityPage.dateRange.presetRangeSevenDays')]: [
      getDateNDaysAgo(7),
      currentDate,
    ],
    [t('dataQualityPage.dateRange.presetRangeFourteenDays')]: [
      getDateNDaysAgo(14),
      currentDate,
    ],
    [t('dataQualityPage.dateRange.presetRange30Days')]: [
      getDateNDaysAgo(30),
      currentDate,
    ],
  }

  const allOrderChannelsOptionValue = t('dataQualityPage.orderChannels.all')
  const allOrderChannelsOption = {
    key: allOrderChannelsOptionValue,
    label: allOrderChannelsOptionValue,
    value: allOrderChannelsOptionValue,
  }

  return (
    <div className={styles.dataQualityFilterContainer}>
      <div className={styles.leftFormGroup}>
        <div className={styles.filterInput}>
          <div className={styles.dateRangeFormLabel}>
            <Text size="sm">{t('dataQualityPage.dateRangeA')}</Text>
            <Tooltip
              className={styles.tooltip}
              title={t('dataQualityPage.rangeAMustBeOlderThanRangeB')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
          <RangePicker
            value={[fromDateA, toDateA]}
            onChange={(value) => handleRangeChange(value, 'rangeA')}
            allowClear
            ranges={ranges}
          />
        </div>
        <div className={styles.filterInput}>
          <div className={styles.dateRangeFormLabel}>
            <Text size="sm">{t('dataQualityPage.dateRangeB')}</Text>
            <Tooltip
              className={styles.tooltip}
              title={t('dataQualityPage.rangeAMustBeOlderThanRangeB')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>

          <RangePicker
            value={[fromDateB, toDateB]}
            onChange={(value) => handleRangeChange(value, 'rangeB')}
            allowClear
            disabledDate={(current) => {
              return current && toDateA
                ? current.isSameOrBefore(toDateA)
                : false
            }}
            ranges={ranges}
          />
        </div>
        <div className={styles.filterInput}>
          <div className={styles.dateRangeFormLabel}>
            <Text className={styles.orderChannelLabel} size="sm">
              {t('dataQualityPage.orderChannel')}
            </Text>
          </div>

          <Select
            className={styles.orderChannelSelect}
            defaultValue={
              t('dataQualityPage.orderChannels.all') as OrderChannelSelect
            }
            onChange={handleOrderChannelChange}
            options={[
              allOrderChannelsOption,
              ...Object.values(INTEGRATION_ANALYSIS_ORDER_CHANNEL).map(
                (channel) => ({
                  key: channel,
                  label: t(
                    `columnFilters.inputs.multiselect.orderChannel.${channel}`
                  ),
                  value: channel,
                })
              ),
            ]}
          />
        </div>
      </div>
      <div className={styles.rightFormGroup}>
        <div>
          <Button disabled={!fromDateA} type="primary" onClick={handleCompare}>
            {t('dataQualityPage.compareData')}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              resetTableFilters('rangeA')
              resetTableFilters('rangeB')
              handleReset()
            }}
            type="link"
          >
            {t('dataQualityPage.clear')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DataComparisonFilters
