export default {
  title: 'Teams',
  createTestTeamButton: 'Create Test Team',
  noResults: {
    title:
      'Manage an existing team <br /> or create a test team to represent your online store',
    description:
      'Use the selection on top to find an existing team<br/>or start a new integration by clicking "+ Create test team".<br/><br/>A completed integration consists of two teams:<br/>a <strong>test team</strong> and a <strong>production team</strong>. You only need to create one!<br/>Once the integration is complete, we’ll create a production team for you.',
  },
  teamCard: {
    copySuccess: 'Copied to clipboard',
    copyFailure: 'Something went wrong',
    actionTitles: {
      settings: 'Settings',
      resources: 'Integration Resources',
      monitor: 'Verify Data Quality',
    },
    lastCase: 'Last case ',
    noOrders: 'No orders received yet',
    descriptions: {
      apiKey: 'Api Key',
      autoSubmit: 'Auto Submit For Guarantee',
    },
  },
  integrationResourcesModal: {
    title: 'Integration Resources',
    description:
      "Based on your team details, we've gathered specific resources:",
    resourceItems: {
      integrationGuide:
        'View the <externalLink>Signifyd Integration Guide</externalLink> to learn how to start sending orders.',
      api: 'Refer to <externalLink>Signifyd API</externalLink> for methods, requests, and responses.',
      php: 'Save time by using the <externalLink>Signifyd PHP client library.</externalLink>',
      mobile:
        'Integrate to your application using our <externalLink>mobile SDKs.</externalLink>',
      authorizeDotNet:
        'Once you have obtained your Authorize.Net API Login ID and Transaction Key, <externalLink>configure this team’s integration Settings.</externalLink>',
    },
    dismissButton: 'Got it',
  },
  teamCreationSuccessModal: {
    title: 'Success!',
    description1: 'The team was created and a test API key is now available.',
    description2:
      'Ready to start integration? <br />We’ve gathered specific resources for this integration.',
    viewIntegrationButton: 'View Integration Resources',
    viewTeamDetailsButton: 'Or, review your team details instead',
  },
  invalidTeamWarning:
    'Invalid URL provided. Please choose a Team on this page to view Teams details.',
}
