import { FC, ReactNode } from 'react'
import { Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'
import cx from 'classnames'
import styles from './TableTag.less'
import Badge, { BadgeProps } from './Badge'

export interface TableTagProps extends Omit<TagProps, 'color'> {
  children: ReactNode
  color: string
  type: 'primary' | 'secondary'
  badge?: BadgeProps
  disableAnimation?: boolean
}

// TODO FET-1237 https://signifyd.atlassian.net/browse/FET-1237
// Replace SigTag with this implementation
const TableTag: FC<TableTagProps> = ({
  color,
  type,
  badge,
  children,
  className,
  disableAnimation,
  ...tagProps
}) => {
  return (
    <Tag
      className={cx([
        className,
        styles[type],
        styles.sigTag,
        styles.tagOverride,
        disableAnimation && styles.disableAnimation,
      ])}
      color={color}
      {...tagProps}
    >
      {badge?.position === 'start' && <Badge {...badge} />}
      {children}
      {badge?.position === 'end' && <Badge {...badge} />}
    </Tag>
  )
}
export default TableTag
