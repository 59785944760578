import { FC } from 'react'
import { Input as AntInput } from 'antd'
import { useField } from 'formik'
import { InputProps as AntInputProps } from 'antd/lib/input'
import { FormikFieldProps } from '../form.types'

type InputProps = FormikFieldProps & AntInputProps

const Input: FC<InputProps> = (props) => {
  const { name, validate, ...otherProps } = props
  const [field] = useField({ name, validate })

  return <AntInput {...field} {...otherProps} />
}

export default Input
