
      import API from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../../../node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!../../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[5].use[3]!./DateRangeIndicator.less";
      
      

var options = {"attributes":{"data-override":"app"}};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = (element) => {
  const orderedStyleSelectors = [
    'antd-reset',
    'sig-ant',
    'sig-components',
    'app',
  ]

  const head = document.querySelector('head')

  const elementSelector = element.dataset.override

  const elementSelectorIndex = orderedStyleSelectors.indexOf(elementSelector)

  // Can we insert this style tag after any existing tags above or at this tag's priority?
  const tags = []

  orderedStyleSelectors
    .slice(0, elementSelectorIndex + 1)
    .forEach((selector) => {
      const existingTags = [
        ...document.querySelectorAll(
          `head > style[data-override="${selector}"]`
        ),
      ]

      tags.push(...existingTags)
    })

  if (tags.length) {
    const lastTag = tags[tags.length - 1]

    head.insertBefore(element, lastTag.nextSibling)

    return
  }

  // If not, can we insert this before any existing lower priority tags?
  if (elementSelectorIndex < orderedStyleSelectors.length) {
    const tags = []

    orderedStyleSelectors
      .slice(elementSelectorIndex + 1)
      .forEach((selector) => {
        const existingTags = [
          ...document.querySelectorAll(
            `head > style[data-override="${selector}"]`
          ),
        ]
        tags.push(...existingTags)
      })

    if (tags.length) {
      head.insertBefore(element, tags[0])

      return
    }
  }

  // Base case, just append the element
  head.appendChild(element)
};
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!../../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[5].use[3]!./DateRangeIndicator.less";
       export default content && content.locals ? content.locals : undefined;
