import { FC } from 'react'
import { P } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import styles from './TeamOverviewCard.less'

const TeamOverviewCard: FC = () => {
  const { t } = useTranslation()

  return (
    <Card className={styles.card} title={t('resourcesPage.teamOverview.title')}>
      <P className={styles.subTitle}>
        {t('resourcesPage.teamOverview.description.normalText')}{' '}
        <strong>
          <i>{t('resourcesPage.teamOverview.description.boldText')}</i>
        </strong>
      </P>

      <img
        src={`${ASSET_PATH}/assets/images/integration-overview.jpg`}
        className={styles.img}
        alt={t('resourcesPage.teamOverview.altText')}
      />
    </Card>
  )
}

export default TeamOverviewCard
